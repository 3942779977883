import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import ReactLoading from 'react-loading';
import { delay } from '../Utils';
// import swal from '@sweetalert/with-react';
import swal from '@sweetalert/with-react';

class ImportacaoDB extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			modal: { show: false },
			edit: false,
			importStats: { message: 'Not started', info: [] },
			filterChanged: '',
			data: []
		};

		this.handleImport = this.handleImport.bind(this);
		this.submitData = this.submitData.bind(this);
	}

	handleImport(e) {
		e.preventDefault();

		swal({
			title: 'Atenção!',
			text: 'Deseja importar o banco de dados?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	async submitData(e) {
		this.setState({ importStats: { message: 'Started', info: [] } });
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/startImportDB', {
			method: 'GET',
			headers: {
				'Content-type': 'application/json'
			}
		})
			.then(async (r) => r.json())
			.then(async (r) => {
				console.log(r);
				if (r.message === 'Success!') {
					while (this.state.importStats.message !== 'Done') {
						await fetch(
							config.protocol + '://' + config.server + ':' + config.portBackend + '/api/checkImportDB',
							{
								method: 'GET',
								headers: {
									'Content-type': 'application/json'
								}
							}
						)
							.then(async (r) => r.json())
							.then(async (r) => {
								this.setState({ importStats: r });
								swal({
									title: 'Importando banco de dados',
									text: this.state.importStats.message,
									// icon: "info",
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#008ca4'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});
							});
						if (this.state.importStats.message === 'Error') {
							swal('Importação não concluída', 'Houve um problema na importação.', 'error');
							break;
						}
						await delay(1000);
					}
					swal('Importação concluída', 'As informações foram importadas com sucesso!', 'success');
				} else {
					swal('Importação não concluída', 'Houve um problema na importação.', 'error');
				}
			});
	}

	async componentDidMount() {}

	render() {
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Importar Banco de Dados</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleImport}
							>
								Importar
							</button>
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '10px' }}
								onClick={this.handleBack}
							>
								Voltar
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ImportacaoDB;
