import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import './css/Default.css';
import './css/Table.css';
import 'semantic-ui-css/semantic.min.css';
import Cidades from './pages/Cidades';
import Estados from './pages/Estados';
import Paises from './pages/Paises';
import Parametros from './pages/Parametros';
import Usuarios from './pages/Usuarios';
import Home from './pages/Home';
import Login from './pages/Login';
import Associados from './pages/Associados';
import AreaAtuacao from './pages/AreaAtuacao';
import RegistroAssociados from './pages/RegistroAssociados';
import CategoriasAssociados from './pages/CategoriasAssociados';
import Universidades from './pages/Universidades';
import Regioes from './pages/Regioes';
import CategoriasArquivos from './pages/CategoriasArquivos';
import Ligas from './pages/Ligas';
import RegistroLigas from './pages/RegistroLigas';
import Sociedades from './pages/Sociedades';
import RegistroSociedades from './pages/RegistroSociedades';
import TiposSociedades from './pages/TiposSociedades';
import ValoresAnuidades from './pages/ValoresAnuidades';
import ImportacaoDB from './pages/ImportacaoDB';
import CentroEspecializacao from './pages/CentroEspecializacao';
import UnificarCadastros from './pages/UnificarCadastros';
import MicroRegioes from './pages/MicroRegioes';
import CidadesMicroRegioes from './pages/CidadesMicroRegioes';
import Anuidades from './pages/Anuidades';
import RetornosUnicred from './pages/RetornosUnicred';
import RelatorioSociosRemidos from './pages/RelatorioSociosRemidos';
import RelatorioRecebimentoSocios from './pages/RelatorioRecebimentoSocios';
import RelatorioSociosEmDia from './pages/RelatorioSociosEmDia';
import RelatorioListagemAssociados from './pages/RelatorioListagemAssociados';
import RelatorioListagemLigas from './pages/RelatorioListagemLigas';
import RelatorioListagemSociedades from './pages/RelatorioListagemSociedades';
import RelatorioListagemAssociadosPorCET from './pages/RelatorioListagemAssociadosPorCET';

class App extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/sargs/login" component={Login} />
					<ProtectedRoute path="/sargs/home" component={Home} />
					<ProtectedRoute exact path="/sargs/cidades" component={Cidades} />
					<ProtectedRoute exact path="/sargs/estados" component={Estados} />
					<ProtectedRoute exact path="/sargs/paises" component={Paises} />
					<ProtectedRoute exact path="/sargs/parametros" component={Parametros} />
					<ProtectedRoute exact path="/sargs/usuarios" component={Usuarios} />
					<ProtectedRoute exact path="/sargs/associados" component={Associados} />
					<ProtectedRoute exact path="/sargs/valores-anuidades" component={ValoresAnuidades} />
					<ProtectedRoute exact path="/sargs/importacao-db" component={ImportacaoDB} />
					<ProtectedRoute exact path="/sargs/associados/registro" component={RegistroAssociados} />
					<ProtectedRoute exact path="/sargs/categorias-associados" component={CategoriasAssociados} />
					<ProtectedRoute exact path="/sargs/categorias-arquivos" component={CategoriasArquivos} />
					<ProtectedRoute exact path="/sargs/regioes" component={Regioes} />
					<ProtectedRoute exact path="/sargs/micro-regioes" component={MicroRegioes} />
					<ProtectedRoute exact path="/sargs/universidades" component={Universidades} />
					<ProtectedRoute exact path="/sargs/centro-especializacao" component={CentroEspecializacao} />
					<ProtectedRoute exact path="/sargs/ligas" component={Ligas} />
					<ProtectedRoute exact path="/sargs/ligas/registro" component={RegistroLigas} />
					<ProtectedRoute exact path="/sargs/tipos-sociedades" component={TiposSociedades} />
					<ProtectedRoute exact path="/sargs/sociedades" component={Sociedades} />
					<ProtectedRoute exact path="/sargs/sociedades/registro" component={RegistroSociedades} />
					<ProtectedRoute exact path="/sargs/funcoes/unificar-cadastros" component={UnificarCadastros} />
					<ProtectedRoute exact path="/sargs/micro-regioes/incluir-cidades" component={CidadesMicroRegioes} />
					<ProtectedRoute exact path="/sargs/anuidades" component={Anuidades} />
					<ProtectedRoute exact path="/sargs/relatorios/socios-remidos" component={RelatorioSociosRemidos} />
					<ProtectedRoute exact path="/sargs/relatorios/recebimento-socios" component={RelatorioRecebimentoSocios} />
					<ProtectedRoute exact path="/sargs/relatorios/socios-em-dia" component={RelatorioSociosEmDia} />
					<ProtectedRoute exact path="/sargs/relatorios/listagem-associados" component={RelatorioListagemAssociados} />
					<ProtectedRoute exact path="/sargs/relatorios/listagem-associados-cet" component={RelatorioListagemAssociadosPorCET} />
					<ProtectedRoute exact path="/sargs/relatorios/listagem-ligas" component={RelatorioListagemLigas} />
					<ProtectedRoute exact path="/sargs/relatorios/listagem-sociedades" component={RelatorioListagemSociedades} />
					<ProtectedRoute exact path="/sargs/retornos-unicred" component={RetornosUnicred} />
					<ProtectedRoute exact path="/sargs/area-atuacao" component={AreaAtuacao} />
					<ProtectedRoute component={Home} />
				</Switch>
			</Router>
		);
	}
}

export default App;
