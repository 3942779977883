import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName } from '../Utils';
// import moment from 'moment';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';

class Sociedades extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	// populaLotacao(item, id) {
	// 	if (item.type === 'lotacao') {
	// 		return (
	// 			<option value={item.value} key={id}>
	// 				{item.display}
	// 			</option>
	// 		);
	// 	}
	// }

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Lotação
		// await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLotacoes')
		// 	.then((r) => r.json())
		// 	.then(async (r) => {
		// 		let combosLotacao = this.state.combos;
		// 		await asyncForEach(r, async (item) => {
		// 			let lotacao = {
		// 				type: 'lotacao',
		// 				display: item.descricao,
		// 				value: item.pk_lot
		// 			};
		// 			await combosLotacao.push(lotacao);
		// 		});
		// 	});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = getParameterByName('filtered');
		query.nome = getParameterByName('nome');
		query.matricula_sba = getParameterByName('matricula_sba');
		query.numero_crm = getParameterByName('numero_crm');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteSociedades?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/sargs/sociedades'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_fundacao = garanteDate(item.data_fundacao);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {
				//Filtro: Nome
				let nome = (item.nome || '').includes((filter.nome || '').toUpperCase());

				//Filtra
				return nome;
			});

			let queryString = '?';

			if (filter.nome) {
				if (queryString === '?') {
					queryString = queryString + 'nome=' + filter.nome;
				} else queryString = queryString + '&nome=' + filter.nome;
			}

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/sargs/sociedades' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/sargs/sociedades?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSociedades')
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				let items = await this.filterData(r);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
	}

	render() {
		// let lotacoes = this.state.combos.map(this.populaLotacao);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Sociedades" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Sociedades</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Nome</label>
									<input
										name="nome"
										type="text"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.nome || ''}
										onChange={this.handleChange}
									/>
								</div>
								<br />
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<LinkContainer to={'/sargs/sociedades/registro'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo
										Registro
									</button>
								</LinkContainer>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Sociedade',
											accessor: 'nome',
											width: 230
										},
										{
											Header: 'Tipo de Sociedade',
											accessor: 'descricao_tip',
											width: 120
										},
										{
											Header: 'Endereço',
											accessor: 'logradouro',
											width: 250
										},
										{
											Header: 'Cidade',
											accessor: 'cidade',
											width: 200
										},
										{
											Header: 'Opções',
											minWidth: 300,
											Cell: (row) => {
												return (
													<div className="buttonsDetailColumn">
														<LinkContainer
															to={'/sargs/sociedades/registro?pk=' + row.row.pk_soc}
														>
															<button className="buttonDetailColumn">
																<Icon
																	size={20}
																	icon={edit}
																	style={{ marginRight: '5px', marginLeft: '-5px' }}
																/>Editar
															</button>
														</LinkContainer>
														<button
															className="buttonDetailColumn"
															onClick={(e) => {
																this.handleDelete(e, row.row.pk_soc);
															}}
															name={row.row.pk_ass}
														>
															<Icon
																size={20}
																icon={iosTrash}
																style={{ marginRight: '5px', marginLeft: '-5px' }}
															/>Excluir
														</button>
													</div>
												);
											}
										},
										{
											Header: 'Código',
											accessor: 'pk_soc',
											show: false
										}
									]}
									defaultSorted={[
										{
											id: 'nome',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Sociedades;
