import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
// import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName, onBlurCurrency, populateForm  } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
// import { edit } from 'react-icons-kit/ionicons/edit';
// import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
// import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import Autocomplete from '../components/Autocomplete';
// import {fileExcel} from 'react-icons-kit/icomoon/fileExcel'
import {credit} from 'react-icons-kit/entypo/credit'
import {cw} from 'react-icons-kit/entypo/cw'
import { Modal } from 'react-bootstrap';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class Anuidades extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			total: 0, 
			totalRecebido: 0, 
			totalPendente: 0,
			totalSelecionado: 0,
			filterChanged: '',
			selected: [],
			selectedData: [],
			columns: [],
			modal: { show: false },
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
		this.filterDependente = this.filterDependente.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.populaSelectedColumns = this.populaSelectedColumns.bind(this);
		this.populaUnselectedColumns = this.populaUnselectedColumns.bind(this);
		this.exportacaoSelectAll = this.exportacaoSelectAll.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.toggleSelectRow = this.toggleSelectRow.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.submitData = this.submitData.bind(this);
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	async showModal(e, codigo) {
		e.preventDefault();

		let edicao = false;
		let pk = '0';
		if (Number(codigo) > 0) {
			edicao = true;
			pk = codigo;
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getAnuidades?pk=' +
					Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					// await fetch(config.backend+'/getCelulares?pk='+(Number(e.target.id)).toString()).then(r => r.json()).then(async r => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/anuidades';
					} else {
						let form = document.getElementById('registroAnuidades');
						let selectedAssociado = this.state.combos.filter((item) => {
							return item.type === 'associados' && item.value === r[0].fk_ass;
						});
						console.log(codigo, r[0].fk_ass, selectedAssociado[0])
						this.setState({
							selected: {
								associado: selectedAssociado[0]
							},
							registroAnuidade: r[0]
						});
						r[0].str_data_vencimento = garanteDate(r[0].data_vencimento)
						r[0].valor = r[0].valor.toFixed(2).replace('.', ',');
						document.getElementById('registroPagamento').reset();
						await populateForm(form, r[0]);
					}
				});
		} else {
			this.setState({
				selected: {
					associado: []
				}
			});
			edicao = false;
			document.getElementById('registroAnuidades').reset();
			document.getElementById('registroPagamento').reset();
		}
		this.setState({ modal: { show: true }, edit: edicao, codigo: pk });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	
	toggleColumn(e, name) {
		e.preventDefault();
		let columns = this.state.columns;
		asyncForEach(columns, (item, index) => {
			if (item.value === name) item.selected = !item.selected
			if (index+1 === columns.length) this.setState({columns: columns})
		})	
	}

	populaSelectedColumns(item, id) {
		if (item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)} key={id}> 
					{item.value}
				</li>
			);
		}
	}

	populaUnselectedColumns(item, id) {
		if (!item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)} key={id}>
					{item.value}
				</li>
			);
		}
		
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});


		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = getParameterByName('filtered');
		getParameterByName('filtered');
		query.fk_ass = getParameterByName('fk_ass');
		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];
		query.pk = getParameterByName('pk')

		query.situacao = getParameterByName('situacao') || 'A';
		query.ano = getParameterByName('ano');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);

	}


	exportacaoSelectAll(e,select) {
		e.preventDefault();
		let columns = this.state.columns
		asyncForEach(columns, (item, index)=>{
			item.selected = select
			if (index+1 === columns.length) this.setState({columns: columns})
		})
	}

	async handleExport () {
		let columns = this.state.columns;
		let selected = columns.filter((item) => {return item.selected === true}).length > 0;
		let data = this.state.data;
		let haveData = data.length > 0;
		// console.log(columns, selected)
		console.log(data)
		let exportData = {columns: columns, data: data};
		if (!selected) swal('Nenhuma coluna selecionada!!', 'Nenhuma coluna foi selecionada. Selecione pelo menos uma para exportar os registros!', 'error');
		if (!haveData) swal('Nenhum registro encontrado!!', 'Nenhum registro filtrado!! Filtre ao menos um registro para gerar a exportação.', 'error');
		if (haveData && selected) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/exportacaoLivre?table=associados',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(exportData)
				}
			).then((r) => r.blob())
			.then((r) => {
				var url = window.URL.createObjectURL(r);
				var a = document.createElement('a');
				a.href = url;
				a.download = "exportacao.xlsx";
				document.body.appendChild(a);
				a.click();    
				a.remove();
			});	
		}
	}

	handleDelete(e, pk, automatico, liquidado) {
		e.preventDefault();
		if (automatico === 'S')
			return swal('Erro!!', 'Exclusão não realizada. Registro foi gerado automaticamente.', 'error');
		if (liquidado > 0) return swal('Erro!!', 'Exclusão não realizada. Registro possui valor recebido.', 'error');
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteAnuidades?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/sargs/anuidades'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			let total = 0, totalRecebido = 0, totalPendente = 0
			await asyncForEach(data, async (item, index) => {
				total = Number(item.valor) + Number(total);
				totalRecebido = Number(item.valor_recebido) + Number(totalRecebido);

				item.str_data_vencimento = garanteDate(item.data_vencimento);
				item.str_gerou_receita = (item.gerou_receita === 'S' ? 'Sim' : 'Não')
				// item.data_emis = garanteDate(item.data_emis);
				// item.data_liq = garanteDate(item.data_liq);
				
				if (data.length === index+1) {
					totalPendente = Math.max(Number(total) - Number(totalRecebido), 0);
					this.setState({ total: total, totalRecebido: totalRecebido, totalPendente: totalPendente })
					resolve(data);
				}
			});
			
		});
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'O registro de pagamento será gerado. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	async filterData() {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let queryString = '?';

			if (filter.ano) {
				if (queryString === '?') {
					queryString = queryString + 'ano=' + filter.ano;
				} else queryString = queryString + '&ano=' + filter.ano;
			}

			if (filter.pk) {
				if (queryString === '?') {
					queryString = queryString + 'pk=' + filter.pk;
				} else queryString = queryString + '&pk=' + filter.pk;
			}

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}


			if (filter.situacao) {
				if (queryString === '?') {
					queryString = queryString + 'situacao=' + filter.situacao;
				} else queryString = queryString + '&situacao=' + filter.situacao;
			}


			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/sargs/anuidades' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/sargs/anuidades?filtered=true');
			}

			resolve(queryString);
		});
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroPagamento');
		const data = new FormData(form);

		const anuidade = this.state.registroAnuidade

		console.log(data);

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			object[key] = value;
			
		});
		console.log(object)
		object.valor_pagto = Number(object.valor_pagto.replace(',', '.'))
		var json = JSON.stringify({pagamento: object, anuidade});


		console.log(json);
		fetch(
			config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/liquidaAnuidade',
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			}
		)
			.then((r) => r.json())
			.then((r) => {
				console.log(r);
				if (r.message === 'Success!') {
					swal('Pagamento registrado', 'Pagamento de anuidade registrado com sucesso!', 'success').then((result) => {
						this.setState({ modal: { show: false } });
						var event = new Event('build');
						this.getData(event);
					});
				} else {
					swal('Pagamento não registrado', r.error , 'error');
				}
			});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();

		this.setState({ data: [] });

		let query = await this.filterData();
		console.log(query)
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAnuidades'+query)
			.then((r) => r.json())
			.then(async (r) => {
				let colunasExportacao = [
					{value: 'Código', field: 'pk_anu', fk: {}, selected: false, width: 50, align: 3},
					{value: 'Titular', fk: {fkField: 'fk_ass', tablePk: 'pk_anu', tableField: 'nome',table: 'associados'}, field: 'titular', selected: false, width: 200, align: 1},
					{value: 'Referente', fk: {fkField: 'fk_ass', tablePk: 'pk_anu', tableField: 'nome',table: 'associados'}, field: 'nomeass', selected: false, width: 200, align: 1},
					{value: 'Serviço', fk: {fkField: 'fk_ser', tablePk: 'pk_anu', tableField: 'descricao',table: 'tipo_servico'}, field: 'strServico', selected: false, width: 200, align: 1},
					{value: 'Competência', fk: {}, field: 'competencia', selected: false, width: 130, align: 1},
					{value: 'Valor', fk: {}, field: 'valor', selected: false, width: 100, align: 3},
					{value: 'Valor Recebido', fk: {}, field: 'valor_recebido', selected: false, width: 130, align: 3},
					{value: 'Forma Cobrança', fk: {}, field: 'strforma_cobranca', selected: false, width: 130, align: 1},
					{value: 'Data Emissão', fk: {}, field: 'data_emis', selected: false, width: 100, align: 1},
					{value: 'Automático', fk: {}, field: 'automatico', selected: false, width: 100, align: 1},
					{value: 'Sexo', fk: {}, field: 'data_venc', selected: false, width: 100, align: 1},
				];
				//Filtra
				// let items = await this.filterData(r);
				//Trata
				let items = await this.modelingData(r);
				this.setState({ data: items, columns: colunasExportacao });
			});
	}


	populaTiposServico(item, id) {
		if (item.type === 'tiposServico') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}

	filterDependente(item) {
		let newFilter = this.state.filter;
		newFilter.dependente = item.value;
		this.setState({ filter: newFilter });
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2020; i <= 2050; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	toggleSelectAll(e) {
		if (!e.target.checked) {
			this.setState({
				selectedData: [],
				totalSelecionado: 0
			})
		} else {
			let arrayOfPks = this.state.data.map((item) => { return { codigo: item.pk_anu, valor: item.valor} } )
			this.setState({
				selectedData: arrayOfPks,
				totalSelecionado: arrayOfPks.reduce((a, b) => a + b.valor, 0)
			})
		}
	}

	selectAssociado(item) {
		this.setState({
			selected: {
				associado: item
			}
		});
	}
	
	async reabrirAnuidade (e, codigo) {
		swal({
			// dangerMode: true,
			title: 'Atenção!',
			text: 'A cobrança de anuidade selecionada terá todos seus registros de pagamento excluídos. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/reabrirAnuidade?pk_anu=' +
						codigo,
					{
						method: 'DELETE',
						headers: {
							'Content-type': 'application/json'
						}
					}
				)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Anuidade reaberta', 'Registros de pagamento excluídos com sucesso!', 'success').then((result) => {
							//Caso filtered=true, traz form já processado
							var event = new Event('build');
							this.getData(event);
						});
					} else {
						swal('Anuidade não foi reaberta', 'Registros de pagamento não foram excluídos. Verifique os campos.', 'error');
					}
				});
			}
		})
	}

	toggleSelectRow(e, codigo, valor) {
		let { selectedData } = this.state
		if (!e.target.checked) {
			const index = selectedData.map((item) => item.codigo).indexOf(codigo);
			if (index > -1) {
				selectedData.splice(index, 1);
			}
			this.setState({
				...selectedData,
				totalSelecionado: selectedData.reduce((a,b) => a + b.valor, 0)
			})
		} else {
			selectedData.push({ codigo, valor })
			this.setState({
				...selectedData,
				totalSelecionado: selectedData.reduce((a,b) => a + b.valor, 0)
			})
		}
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});
		// let tiposServico = this.state.combos.map(this.populaTiposServico);
		// let selectedColumns = this.state.columns.map(this.populaSelectedColumns)
		// let unselectedColumns = this.state.columns.map(this.populaUnselectedColumns)
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Anuidades" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Anuidades</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Associado</label>
									<Autocomplete
										field="filtroFk_ass"
										items={associados}
										inModal=""
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Ano</label>
									<select
										name="ano"
										id="filtroAno"
										className="selectFiltro"
										value={this.state.filter.ano || ''}
										onChange={this.handleChange}
									>
										<option value="">Ano</option>
										{anos}
									</select>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Situação</label>
									<select
										name="situacao"
										id="filtroSituacao"
										className="selectFiltro"
										value={this.state.filter.situacao || 'A'}
										onChange={this.handleChange}
									>
										<option value="A">EM ABERTO</option>
										<option value="R">RECEBIDAS</option>
										<option value="T">TODAS</option>
									</select>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								{/* <button className="buttonExportacao"  onClick={this.showModal}>
									<Icon size={16} style={{ display: 'inline' }} icon={fileExcel} /> Exportação Livre
								</button>
								<LinkContainer to={'/sargs/anuidades/registro'+(getParameterByName('fk_ass')?'?fk_ass='+getParameterByName('fk_ass'):'')}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} /> Novo
										Registro
									</button>
								</LinkContainer> */}
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className={this.modalState()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
									<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Dados da Cobrança</h3>
											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="registroAnuidades"
													name="registroAnuidades"
													// onSubmit={this.submitData}
												>
													<div>
														<label className="labelModal">Associado</label>
														{/* <Autocomplete
															field="fk_ass"
															items={associados}
															inModal="-inModal"
															selected={this.state.selected.associado}
															select={this.selectAssociado}
															readOnly
														/> */}
														<input
															type="text"
															id="nomeAss"
															name="nomeAss"
															className="form-control inModal"
															data-parse="uppercase"
															readOnly
														/>
													</div>
													<div>
														<label className="labelModal">Ano</label>
														<input
															type="text"
															id="ano"
															name="ano"
															className="form-control inModal"
															style={{ width: '60px' }}
															data-parse="uppercase"
															readOnly
														/>
													</div>
													<div>
														<label className="labelModal">Data Vencimento:</label>
														<input
															type="text"
															id="str_data_vencimento"
															name="str_data_vencimento"
															className="form-control inModal"
															// style={{ width: '60px' }}
															data-parse="uppercase"
															readOnly
														/>
													</div>
													<div>
														<label className="labelModal">Valor (R$):</label>
														<input
															type="text"
															id="valor"
															name="valor"
															className="form-control inModal"
															onBlur={(e) => {
																onBlurCurrency(e);
																// this.calculaPendente(e);
															}}
															readOnly
														/>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Dados do Pagamento</h3>
											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="registroPagamento"
													name="registroPagamento"
													onSubmit={this.submitData}
												>
													<div>
														<label className="labelModal">Forma de Pagamento</label>
														<select
															name="forma_pagto"
															id="forma_pagto"
															className="form-control inModal"
														>
															<option value="B">Boleto</option>
															<option value="D">Depósito em Conta</option>
															<option value="C">Cartão de Crédito</option>
															<option value="T">Transferência (DOC/TED)</option>
															<option value="P">Pix</option>
															<option value="O">Outros</option>
														</select>
													</div>
													<div>
														<label className="labelModal">Data de Pagamento</label>
														<input
															type="date"
															id="data_pagto"
															name="data_pagto"
															className="form-control inModal"
														/>
													</div>
													<div>
														<label className="labelModal">Valor do Pagamento (R$):</label>
														<input
															type="text"
															id="valor_pagto"
															name="valor_pagto"
															className="form-control inModal"
															onBlur={(e) => {
																onBlurCurrency(e);
																// this.calculaPendente(e);
															}}
														/>
													</div>
													<div>
														<label className="labelModal">Gera Receita</label>
														<select
															name="gera_receita"
															id="gera_receita"
															className="form-control inModal"
															style={{ width: '100px' }}
														>
															<option value="S">Sim</option>
															<option value="N">Não</option>
														</select>
													</div>
													<div>
														<label className="labelModal">Observação</label>
														<textarea
															type="text"
															name="observacao"
															className="form-control inModal"
															data-parse="uppercase"
														/>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.handleSave}>
													Salvar
												</button>
												<button className="ModalButton" onClick={this.closeModal}>
													Cancelar
												</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											id: 'selection',
											Header: (header) => {
												header.column.sortable = false
												return (
												<div>
													<input type="checkbox" onChange={this.toggleSelectAll} checked={this.state.selectedData.length === this.state.data.length}/>
												</div>
											)},
											Cell: ({ row }) => (
												<div>
													<input type="checkbox" onChange={(e) => this.toggleSelectRow(e, row.pk_anu, row.valor)} checked={this.state.selectedData.map((item) => item.codigo).indexOf(row.pk_anu) > -1} />
												</div>
											),
											width: 47,
										},
										{
											Header: 'Codigo',
											accessor: 'pk_anu',
											width: 80,
											show: false
										},
										{
											Header: '',
											width: 65,
											Cell: (row) => {
												return (
													<div>
														{/* <Icon
															size={20}
															icon={edit}
															name={row.row.pk_anu}
															className="iconButton"
															onClick={(e) => {
																this.showModal(e, row.row.pk_anu);
															}}>
														</Icon> */}
														<Icon
															size={20}
															icon={credit}
															name={row.row.pk_anu}
															className="iconButton"
															onClick={(e) => {
																if (row.row.valor_recebido >= row.row.valor) {
																	swal('Registro já liquidado!', '', 'error');
																} else {
																	console.log(row.row)
																	this.showModal(e, row.row.pk_anu)
																}
															}}>
														</Icon>
														<Icon
															size={20}
															icon={cw}
															name={row.row.pk_anu}
															className="iconButton"
															onClick={(e) => {
																if (row.row.valor_recebido > 0) {
																	this.reabrirAnuidade(e, row.row.pk_anu)
																} else {
																	swal('Registro não possui pagamento!', '', 'error');
																}
															}}>
														</Icon>
														{/* <Icon
															size={20}
															icon={iosTrash}
															name={row.row.pk_anu}
															className="iconButton"
															onClick={(e) => {
																this.handleDelete(e, row.row.pk_anu);
															}}
														/> */}
													</div>
												);
											}	
										},
										{
											Header: 'Associado',
											accessor: 'nomeAss',
											width: 400
										},
										{
											Header: 'Ano',
											accessor: 'ano',
											width: 80
										},
										{
											Header: 'Valor',
											accessor: 'valor',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Recebido',
											accessor: 'valor_recebido',
											width: 100,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_recebido).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Pendente',
											accessor: 'valor_recebido',
											width: 100,
											Cell: (row) => {
												return (
													<div>
														{Number((row.original.valor_recebido > row.original.valor ? 0 : row.original.valor - row.original.valor_recebido)).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
									]}
									defaultSorted={[
										{
											id: 'data_venc',
											desc: true
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
							<p style={{float: 'right'}}>{this.state.data.length+' registros encontrados'}</p>
							<p>{
								'Total: '+this.state.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Recebido: '+this.state.totalRecebido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Pendente: '+this.state.totalPendente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Selecionado: '+this.state.totalSelecionado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
							}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Anuidades;
