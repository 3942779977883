import React, { Component } from 'react';
import config from '../config'
import NavBar from '../components/NavBar'
import '../css/Default.css';
import Autocomplete from '../components/Autocomplete';
import { Modal } from 'react-bootstrap'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { onBlurCurrency, getParameterByName, asyncForEach, populateForm } from '../Utils'
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit'
import {edit} from 'react-icons-kit/ionicons/edit'
import {iosTrash} from 'react-icons-kit/ionicons/iosTrash'
import {ic_add_circle} from 'react-icons-kit/md/ic_add_circle'



const inputParsers = {
    date(input) {
        const [month, day, year] = input.split('/');
        return `${year}-${month}-${day}`;
    },
    uppercase(input) {
        return input.toUpperCase();
    },
    number(input) {
        return parseFloat(input);
    }
};





class ValoresAnuidades extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            combos: [],
            categoriaAssociado: {},
            modal: {show: false},
            selected: {
                regiao: []
            },
            filter: [],
            filterChanged: '',
            edit: false,
            modalIsOpen: false,
            data: []
        };
        this.submitData = this.submitData.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleClean = this.handleClean.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.showModal = this.showModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.modalState = this.modalState.bind(this)
        this.selectRegiao = this.selectRegiao.bind(this)

    }

    closeModal() {
        swal({
            dangerMode: true,
            title: "Atenção!",
            text: "Ao cancelar, todas as alterações serão descartadas. Confirma?",
            buttons: ["Sim", "Não"],
        }).then((result) => {
            if (!result) this.setState({ modal: { show: false } })
        });
        
    }

    async showModal(e, codigo) {
        // e.stopPropagation()
        e.preventDefault();
        console.log(e.target)
        let edicao = false
        let pk = '0'
        if (Number(codigo) > 0) {
            edicao = true
            pk = codigo
            await fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/getValoresAnuidades?pk='+(Number(codigo)).toString()).then(r => r.json()).then(async r => {
            // await fetch(config.backend+'/getCelulares?pk='+(Number(e.target.id)).toString()).then(r => r.json()).then(async r => {
                if (typeof r[0] === 'undefined') {
                    window.location.href = '/categorias-associados'
                } else {
                    let form = document.getElementById('registroValoresAnuidades');
                    let selectedRegiao = this.state.combos.filter((item) => {
                        return item.type === 'regioes' && item.value === r[0].fk_reg;
                    });
                    r[0].valor = r[0].valor.toFixed(2).replace('.', ',');
                    this.setState({
                        selected: {
                            regiao: selectedRegiao[0]
                        }
                    });
                    await populateForm(form, r[0])
                }  
            })
        } else {
            edicao = false
            this.setState({
				selected: {
					regiao: []
				}
			});
            document.getElementById("registroValoresAnuidades").reset();
        }
        this.setState({ modal: { show: true }, edit: edicao, codigo: pk })
    }

    modalState(){
        if (this.state.modal.show === true) {
            return 'ModalShow'
        } else {
            return 'ModalHide'
        }
    }

    selectRegiao(item) {
		this.setState({
			selected: {
				regiao: item
			}
		});
	}

    async componentDidMount() {  
            
        let combos = []

        //Regioes
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRegioes')
        .then((r) => r.json())
        .then(async (r) => {
            // let combosRegioes = [];
            await asyncForEach(r, async (item) => {
                let regioes = {
                    type: 'regioes',
                    display: item.descricao,
                    value: item.pk_reg
                };
                await combos.push(regioes);
            });
            this.setState({ combos: combos });
        });


        //Buscar informações do Associado
        let query = {}
        query.fk_cat = Number(getParameterByName('fk_cat'))

        if (query.fk_cat > 0) {
            
            fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/getCategoriasAssociados?pk='+query.fk_cat).then(r => r.json()).then(async r => {
                
                if (typeof r[0] === 'undefined') {
                    window.location.href = '/categorias-associados'
                } else {
                    // console.log(r[0])
                    this.setState({ categoriaAssociado: r[0] })
                    fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/getValoresAnuidades?fk_cat='+query.fk_cat).then(r => r.json()).then(async r => {
                        console.log(r[0])
                        let items = await this.modelingData(r)
                        this.setState({data: items})
                    })
                }

            })
            this.setState({edit: true})

        }


    }

    handleChange(e) {
        //Popula inputs do filtro
        e.preventDefault()
        let target = e.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        let name = target.name
        let reg = this.state.filter
        reg[name] = value
        this.setState({
            filter : reg
        })
        
    }

    handleClean(e) {
        //Limpa o filtro

    }

    async modelingData(data) {
        //Trata os campos
        return new Promise(async (resolve)=>{
            await asyncForEach(data, async (item)=>{
               
                //Pega texto do Inativo
                let inativo = item.inativo === 'S' ? 'Sim' : 'Não'
                item.inativo_nome = inativo
                item.str_principal = item.principal === 'S' ? 'Sim' : 'Não'
            })
            resolve(data)
        })
    }

    handleSave(e) {
        e.preventDefault()
        swal({
            title: "Atenção!",
            text: this.state.edit ? "As alterações no registro serão salvas. Confirma?" : "O registro será incluído. Confirma?",
            buttons: ["Sim", "Não" ],
        }).then((result) => {
            if (!result) this.submitData(e)
        }); 
    }

    handleBack(e) {
        e.preventDefault()
        window.history.back()
    }

    handleDelete(e, pk) {
        e.preventDefault();
        swal({
            dangerMode: true,
            title: "Atenção!",
            text: "O registro selecionado será excluído. Confirma?",
            buttons: ["Sim", "Não"],
        }).then((result) => {
            if (!result) {
                //Delete
                fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/deleteValoresAnuidades?pk='+pk, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(r=>r.json()).then(r=>{
                    console.log(r)
                    if (r.message === "Success!") {
                        swal("Exlusão realizada", "Registro excluído com sucesso!", "success").then((result) => {
                            this.componentDidMount()
                        });
                    } else {
                        swal("Exclusão não realizada", "Registro não foi excluído. Verifique os campos.", "error");
                    }
                })
            }
        });
    }

    submitData(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('registroValoresAnuidades');
        const data = new FormData(form);
        const fk_cat = Number(getParameterByName('fk_cat'))

        //Trata valores conforme data-parse dos inputs
        for (let name of data.keys()) {
            const input = form.elements[name]

            // Rejeita type = undefined (radio inputs)
            if (typeof input.type !== 'undefined') {
                const parserName = input.dataset.parse;
                if (parserName) {
                    const parser = inputParsers[parserName];
                    const parsedValue = parser(data.get(name));
                    data.set(name, parsedValue);
                }
            }
        }

        //Valor para sql
		let valor = data.get('valor');
		valor = valor.replace(',', '.');
		console.log(valor);
		data.set('valor', valor);

        //Insere FK
        data.append('fk_cat', fk_cat)

        //Converte FormData em JSON
        var object = {};
        data.forEach(function(value, key){
            object[key] = value;
        });
        var json = JSON.stringify(object);

        console.log(data.get('inativo'))
        //Post no server
        if (this.state.edit) {
            //Editar
            console.log(json)
            fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/editValoresAnuidades?pk='+this.state.codigo, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: json
            }).then(r=>r.json()).then(r=>{
                console.log(r)
                if (r.message === "Success!") {
                    swal("Alteração realizada", "Registro alterado com sucesso!", "success").then((result) => {
                        this.setState({ modal: { show:false } })
                        this.componentDidMount()
                    });
                } else {
                    swal("Alteração não realizada", "Registro não foi alterado. Verifique os campos.", "error");
                }
            })
        } else {
            //Inserir
            console.log(json)
            fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/novoValoresAnuidades', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: json
            }).then(r=>r.json()).then(r=>{
                console.log(r)
                if (r.message === "Success!") {
                    swal("Inclusão realizada", "Registro incluído com sucesso!", "success").then((result) => {
                        this.setState({ modal: { show:false } })
                        this.componentDidMount()
                    });
                } else {
                    swal("Inclusão não realizada", "Registro não foi incluído. Verifique os campos.", "error");
                }
            })
        }

       

        

    }

    render() {
        let regioes = this.state.combos.filter((item) => {
			return item.type === 'regioes';
		});
        return (
            <div className="boxSite colorSettings">
                {/***************** Barra de Navegação *******************/}
                <div className="boxNavBar">
                    <NavBar selected="Categorias"></NavBar>
                </div>
                {/***************** Tela do WebSite *******************/}
                <div className="boxTela">
                    {/*********************** Header ***********************/}
                    <div className="boxHeader">
                        <h3 className="headerCadastro">Cadastro de Valores de Anuidades</h3>
                    </div>
                    {/*********************** Campos ***********************/}
                    <div style={{ paddingBottom: '30px'}}>
                        <div className={this.modalState()} tabIndex="-1">
                            <Modal.Dialog className="Modal">
                                    <div>
                                    <Modal.Header className="ModalBg">   
                                        <div className="ModalHeader">
                                            <h3 className="headerModal">Registro de Valores de Anuidades</h3>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body className="ModalBg" >   
                                        <div className='ModalBody'> 
                                            <form id="registroValoresAnuidades" name="registroValoresAnuidades" onSubmit={ this.submitData }>
                                                <div>
													<label className="labelModal">Região</label>
													<Autocomplete
														field="fk_reg"
														items={regioes}
														inModal="-inModal"
														selected={this.state.selected.regiao}
														select={this.selectRegiao}
													/>
												</div>
												<div>
													<label className="labelModal">Valor (R$)</label>
													<input
														type="text"
														id="valor"
														name="valor"
														className="form-control"
														style={{ width: '120px' }}
														onBlur={onBlurCurrency}
													/>
												</div>
                                            </form>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="ModalBg">
                                        <div className="ModalFooter">
                                            <button className="ModalButton" onClick={this.handleSave}>Salvar</button>
                                            <button className="ModalButton" onClick={this.closeModal}>Cancelar</button>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal.Dialog>
                        </div>
                        <div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: "30px" }}>
                            <div style={{ height:'auto', marginBottom: '10px' }}>
                                <p style={{ fontSize: '1.2em' }}>Categoria: <font style={{ fontSize: '1.2em',  fontWeight: 'bold'}}>{this.state.categoriaAssociado.descricao}</font></p>
                            </div>
                            {/* <LinkContainer to={"/apergs/associados/celulares/registro?pk="+this.state.associado.pk_ass}> */}
                                <button className="buttonNovo" style={{ marginLeft: '0' }} onClick={this.showModal}><Icon size={20} style={{ display: "inline" }} icon={ic_add_circle}></Icon>Novo Registro</button>
                            {/* </LinkContainer>  */}
                            <br/>
                            <div className="divTabela">
                                <ReactTable
                                    data={this.state.data}
                                    previousText = 'Anterior'
                                    nextText = 'Próximo'
                                    loadingText = 'Carregando...'
                                    pageText = 'Página'
                                    ofText = 'de'
                                    rowsText = 'registros'
                                    noDataText="Nenhum registro encontrado"
                                    columns={[
                                        {
                                            Header: "Código",
                                            accessor: "pk_cag",
                                            show: false
                                        }, 
                                        {
                                            Header: "Região",
                                            accessor: "nomereg",
                                            minWidth: 400
                                        },
										{
											Header: 'Valor',
											// accessor: "inativo_str",
											minWidth: 80,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
                                        {
                                            Header: "Opções",
                                            minWidth: 500,
                                            Cell: row => { return (
                                                <div className="buttonsDetailColumn">
                                                    <button className="buttonDetailColumn" onClick={(e)=>{this.showModal(e, row.row.pk_cag)}}>
                                                        <Icon size={20} icon={edit}></Icon>
                                                        Editar
                                                    </button>
                                                    <button className="buttonDetailColumn" onClick={(e)=>{this.handleDelete(e, row.row.pk_cag)}}>
                                                        <Icon size={20} icon={iosTrash}></Icon>
                                                        Excluir
                                                    </button>
                                                </div>
                                            )}
                                        }
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "str_principal",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                /> 
                            </div>  
                            <div className="buttonsCadastro">
                                <button className="buttonVoltar" style={{marginTop: '10px', marginLeft: '0px'}} onClick={this.handleBack}>Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ValoresAnuidades;
