import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
// import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';

import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';



class UnificarCadastros extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			items: [],
			modal: { show: false },
			query: {
				columnName: '',
				displayColumn: '',
				columnNameFk: '',
				columnNamePk: '',
				setValue: '',
				displayName: ''
			},
			filterChanged: '',
			selected: [],
			columns: [],
			selectedItems: [],
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleUnselectAll = this.handleUnselectAll.bind(this);
		this.handleUnify = this.handleUnify.bind(this);
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	handleClick(e, codigo, selecionado) {
		e.preventDefault();
		let selected = this.state.selected;
		let query = this.state.query;
		let data = this.state.data;
		console.log(e.target.id, selecionado, selected)
		if (selecionado) {
			let filteredData = data.filter((item) => {
				return Number(item[query.columnNamePk]) === Number(codigo)
			})
			let indexData = data.indexOf(filteredData[0])
			data.splice(indexData,1)
			console.log(indexData)
			if (filteredData && filteredData[0]) {
				console.log(filteredData[0])
				selected.push(filteredData[0])
			}
			
		} else {
			let filteredData = selected.filter((item) => {
				return Number(item[query.columnNamePk]) === Number(codigo)
			})
			let indexData = selected.indexOf(filteredData[0])
			selected.splice(indexData,1)
			console.log(indexData)
			if (filteredData && filteredData[0]) {
				console.log(filteredData[0])
				data.push(filteredData[0])
			}			
		}

		this.setState({selected: selected, data: data})
		
	}

	handleSelectAll(e) {
		e.preventDefault();
		let selected = this.state.selected;
		let data = this.state.data;
		selected = selected.concat(data)
		data = []
		this.setState({selected: selected, data: data})
	}

	handleUnselectAll(e) {
		e.preventDefault();
		let selected = this.state.selected;
		let data = this.state.data;
		data = data.concat(selected)
		selected = []
		this.setState({selected: selected, data: data})
	}


	async componentDidMount() {
		//Carregar Parâmetros de pesquisa
		let filter = {};
		let query = {};
		query.tableName = getParameterByName('tableName');
		query.displayColumn = getParameterByName('displayColumn');
		query.columnNamePk = getParameterByName('columnNamePk');
		query.columnNameFk = getParameterByName('columnNameFk');
		query.setValue = getParameterByName('setValue');
		query.displayName = getParameterByName('displayName');
		filter.filtered = true;
		filter.geral = getParameterByName('geral');



		this.setState({
			filter: filter,
			query: query,
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		this.getData(event);	
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		let query = this.state.query;
		let queryString = '?';

		if (query.columnNamePk) {
			if (queryString === '?') {
				queryString = queryString + 'columnNamePk=' + query.columnNamePk;
			} else queryString = queryString + '&columnNamePk=' + query.columnNamePk;
		}

		
		if (query.columnNameFk) {
			if (queryString === '?') {
				queryString = queryString + 'columnNameFk=' + query.columnNameFk;
			} else queryString = queryString + '&columnNameFk=' + query.columnNameFk;
		}

		
		if (query.displayColumn) {
			if (queryString === '?') {
				queryString = queryString + 'displayColumn=' + query.displayColumn;
			} else queryString = queryString + '&displayColumn=' + query.displayColumn;
		}

		
		if (query.tableName) {
			if (queryString === '?') {
				queryString = queryString + 'tableName=' + query.tableName;
			} else queryString = queryString + '&tableName=' + query.tableName;
		}

		
		if (query.setValue) {
			if (queryString === '?') {
				queryString = queryString + 'setValue=' + query.setValue;
			} else queryString = queryString + '&setValue=' + query.setValue;
		}

		if (query.displayName) {
			if (queryString === '?') {
				queryString = queryString + 'displayName=' + query.displayName;
			} else queryString = queryString + '&displayName=' + query.displayName;
		}

		window.history.replaceState({ filtered: false }, 'filter', '/sargs/funcoes/unificar-cadastros'+queryString); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	handleUnify(e) {
		e.preventDefault();
		let params = this.state.query;
		if (this.state.selected && this.state.selected.length > 0) {
			swal({
				dangerMode: true,
				title: 'Atenção!',
				text: 'Os registros serão unificados. Confirma?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) {
					//Gerar
					asyncForEach(this.state.selected, async (item, index) => {
						params.searchValue=+item[params.columnNamePk];
						let query = new URLSearchParams(params);
						let url = config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/juntarRegistros?'+
						query.toString();
						console.log(url)
						console.log(query.toString())
						await fetch(url)
						if (index + 1 === this.state.selected.length) {
							swal({
								icon: "success",
								title: 'Concluído!',
								text: 'Cadastros unificados com sucesso!',
								buttons: false,
								timer: 1500
							}).then((result) => {
								window.history.back();
							})
						}
					})
				}
			});
		} else {
			swal('Selecione pelo menos um registro!', 'Não foi possível unificar os cadastros. Selecione ao menos um registro.', 'error');
		}
		
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_nasc = garanteDate(item.data_nasc);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;
			let query = this.state.query;

			let filtered = await data.filter((item) => {
				//Filtro: Geral
				let valores = JSON.stringify(Object.values(item));
				let geral = true;
				if (filter.geral) {
					geral = (valores || '').includes((filter.geral || '').toUpperCase());
				}

				let setValue = Number(item[query.columnNamePk]) !== Number(query.setValue) 

				let selected = this.state.selected
				let hasSelected = selected.filter((selItem) => {
					return Number(selItem.pk_ass) === Number(item.pk_ass)
				})

				let filtraSelecionado = hasSelected.length === 0

				//Filtra
				return (
					geral &&
					setValue &&
					filtraSelecionado
				);
			});

			let queryString = '?';

			if (filter.geral) {
				if (queryString === '?') {
					queryString = queryString + 'geral=' + filter.geral;
				} else queryString = queryString + '&geral=' + filter.geral;
			}

			
			if (query.columnNamePk) {
				if (queryString === '?') {
					queryString = queryString + 'columnNamePk=' + query.columnNamePk;
				} else queryString = queryString + '&columnNamePk=' + query.columnNamePk;
			}

			
			if (query.columnNameFk) {
				if (queryString === '?') {
					queryString = queryString + 'columnNameFk=' + query.columnNameFk;
				} else queryString = queryString + '&columnNameFk=' + query.columnNameFk;
			}

			
			if (query.displayColumn) {
				if (queryString === '?') {
					queryString = queryString + 'displayColumn=' + query.displayColumn;
				} else queryString = queryString + '&displayColumn=' + query.displayColumn;
			}

			
			if (query.tableName) {
				if (queryString === '?') {
					queryString = queryString + 'tableName=' + query.tableName;
				} else queryString = queryString + '&tableName=' + query.tableName;
			}

			
			if (query.setValue) {
				if (queryString === '?') {
					queryString = queryString + 'setValue=' + query.setValue;
				} else queryString = queryString + '&setValue=' + query.setValue;
			}

			if (query.displayName) {
				if (queryString === '?') {
					queryString = queryString + 'displayName=' + query.displayName;
				} else queryString = queryString + '&displayName=' + query.displayName;
			}

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/sargs/funcoes/unificar-cadastros' + queryString
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/sargs/funcoes/unificar-cadastros');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/get'+getParameterByName('tableName'))
			.then((r) => r.json())
			.then(async (r) => {

				//Filtra
				let items = await this.filterData(r);
				//Trata
				items =  await this.modelingData(items);
				this.setState({ data: items });
			});
	}


	render() {
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="UnificarCadastros" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Unificar Cadastros - Registro Principal: {this.state.query.displayName}</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Geral</label>
									<input
										name="geral"
										type="text"
										id="filtroGeral"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.geral || ''}
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela" style={{ width: '48%', float: 'left'}}>
								<div style={{marginBottom: '10px' }}>
									Registros:
									<button 
										className="buttonVoltar" 
										style={{ float:'right' }}
										onClick={this.handleSelectAll}
									>
										Selecionar Todos
									</button>
								</div>
								<div>
									<ReactTable
										data={this.state.data}
										previousText="Anterior"
										nextText="Próximo"
										loadingText="Carregando..."
										pageText="Página"
										ofText="de"
										rowsText="registros"
										noDataText="Nenhum registro encontrado"
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e, handleOriginal) => {
													this.handleClick(e, rowInfo.row[this.state.query.columnNamePk], true)
												}
											}
										}}
										columns={[
											{
												Header: 'Registro',
												accessor: this.state.query.displayColumn,
											},
											{
												Header: 'Código',
												accessor: this.state.query.columnNamePk,
												show: false
											}
										]}
										defaultSorted={[
											{
												id: this.state.query.displayColumn,
												desc: false
											}
										]}
										defaultPageSize={5}
										className="-striped -highlight"
									/>
								</div>
							</div>
							<div className="divTabela" style={{ width: '48%', float: 'right'}}>
								<div style={{marginBottom: '10px' }}>
									Selecionados:
									<button 
										className="buttonVoltar" 
										style={{ float:'right' }}
										onClick={this.handleUnselectAll}
									>
										Limpar Seleção
									</button>
								</div>
								<div>
									<ReactTable
										data={this.state.selected}
										previousText="Anterior"
										nextText="Próximo"
										loadingText="Carregando..."
										pageText="Página"
										ofText="de"
										rowsText="registros"
										noDataText="Nenhum registro selecionado"
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e, handleOriginal) => {
													this.handleClick(e, rowInfo.row[this.state.query.columnNamePk], false)
												}
											}
										}}
										columns={[
											{
												Header: 'Registro',
												accessor: this.state.query.displayColumn,
												// width: 420,
												// Cell: (row) => {
												// 	return (
												// 		<div>
												// 			<p
												// 				id={row.row.pk_ass}
												// 				onClick={(e)=>{this.handleClick(e, false)}}
												// 			>
												// 				{row.row.nome}
												// 			</p>
												// 		</div>
												// 	);
												// }
											},
											{
												Header: 'Código',
												accessor: this.state.query.columnNamePk,
												show: false
											}
										]}
										defaultSorted={[
											{
												id: this.state.query.displayColumn,
												desc: false
											}
										]}
										defaultPageSize={5}
										className="-striped -highlight"
									/>
								</div>
								<div className="buttonsCadastro" style={{ marginTop: '20px' }}>
									<button className="buttonSalvar" onClick={this.handleUnify} style={{ width: '120px' }}>
										Unificar Cadastros
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UnificarCadastros;
