import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { LinkContainer } from 'react-router-bootstrap';

class Home extends Component {
	render() {
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">{/* <h3 className="headerCadastro">Página Inicial</h3> */}</div>
					{/*********************** Filtros ***********************/}
					<div style={{ minHeight: '80vh' }}>
						<div style={{ width: '80vw', marginLeft: '30px', marginRight: '30px' }}>
							<div className="buttonsHome" />
							<LinkContainer to="/sargs/associados">
								<button className="buttonHomeDiv">
									<img
										alt="Associados"
										src={require('../imgs/associados.png')}
										className="buttonHome"
										title="Associados"
									/>
									<br />
									Associados
								</button>
							</LinkContainer>
						</div>
						<div>
							<img alt="Sargs" src={require('../imgs/sargs.png')} title="Sargs" className="logoHome" />
						</div>
					</div>

					{/*********************** Tabela ***********************/}

					{/*********************** Rodapé ***********************/}
				</div>
			</div>
		);
	}
}

export default Home;
