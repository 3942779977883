import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import MultiSelect from "@khanacademy/react-multi-select";
import '../css/Default.css';
import axios from 'axios';
import 'react-table/react-table.css';
// import ReactLoading from 'react-loading';
import { populateForm, asyncForEach } from '../Utils';
// import swal from '@sweetalert/with-react';
import swal from '@sweetalert/with-react';

class RelatorioSociosRemidos extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			relatorio: '',
			categoriasAssociados: [],
			relatorioStats: { message: 'Not started', info: [] }
		};

		this.handleSave = this.handleSave.bind(this);
		this.handleSaveCsv = this.handleSaveCsv.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('relatorio');
		const data = new FormData(form);

		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=SociosRemidos', {
					exportacao: false,
					ano: data.get('ano'),
					ordenacao: data.get('ordenacao'),
					categorias: this.state.categoriasAssociados
				}).then(async (r) => {
					if (r.data.status === 'success') {
						
						this.setState({ relatorio: ''})
						this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/SociosRemidos/'+data.get('ano')+'.pdf'})
					} else this.setState({ relatorio: ''})
					
				})
				
			}
			
		});
		
	}

	handleSaveCsv(e) {
		e.preventDefault();
		const form = document.getElementById('relatorio');
		const data = new FormData(form);

		swal({
			title: 'Atenção!',
			text: 'Deseja exportar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=SociosRemidos', {
					exportacao: true,
					ano: data.get('ano'),
					ordenacao: data.get('ordenacao'),
					categorias: this.state.categoriasAssociados
				}).then(async (r) => {
					if (r.data.status === 'success') {
						
						window.open(config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/SociosRemidos/SociosRemidos.csv', '_none')
					} else this.setState({ relatorio: ''})	
					
				})
				
			}
			
		});
		
	}


	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategoriasAssociados = this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoriasAssociados = {
					type: 'categoriasAssociados',
					display: item.descricao,
					label: item.descricao,
					value: item.pk_cat
				};
				await combosCategoriasAssociados.push(categoriasAssociados);
			});
			let categoriasAssociados = {
				type: 'categoriasAssociados',
				display: 'SEM CATEGORIA',
				label: 'SEM CATEGORIA',
				value: 'null'
			};
			await combosCategoriasAssociados.push(categoriasAssociados);
			let form = document.getElementById('relatorio');
			var d = new Date();
			var year = d.getFullYear();
			populateForm(form, {ano: year});
			this.setState({ combos: this.state.combos });
		});
		
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item} key={id}>
				{item}
			</option>
		);
	}


	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let categoriasAssociados = this.state.combos.filter((item) => {
			return item.type === 'categoriasAssociados';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relatório - Sócios a se tornar Remidos</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="relatorio" name="relatorio" onSubmit={this.submitData}>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Categorias de Associados</label>
									<MultiSelect
										options={categoriasAssociados}
										selected={this.state.categoriasAssociados}
										onSelectedChanged={selected => this.setState({categoriasAssociados: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="ano">Ano</label>
									<select name="ano" className="form-control">
										{anos}
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="ordenacao">Ordenação</label>
									<select name="ordenacao" className="form-control">
										<option value="nome">Nome do Associado</option>
										<option value="data_nasc">Data de Nascimento</option>
									</select>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
						<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar PDF
							</button>
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '5px' }}
								onClick={this.handleSaveCsv}
							>
								Exportar CSV
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioSociosRemidos;
