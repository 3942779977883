// export const config = {
//     protocol: 'https',
//     server : 'delphusback.herokuapp.com',
//     port: 3000,
//     portBackend: 443,
//     frontend: 'http://localhost.com:3000'
// }

// export const config = {
//     protocol: 'http',
//     server : 'localhost',
//     port: 3000,
//     portBackend: 3001,
//     frontend: 'http://localhost.com:3000'
// }

// export const config = {
// 	protocol: 'http', //produção
// //     // protocol: 'https', //teste
// 	server: window.location.hostname, //server produção
// 	// server: 'localhost', //server local
// // 	port: 3000,
// 	portBackend: 21001, //produção
//     // portBackend: 21004, //teste
//     // portBackend: 21003, //local
// 	frontend: 'http://localhost.com:3000'
// };

// export const config = {
// 		// protocol: 'http', //produção
// 	    protocol: 'https', //teste

// 		server: window.location.hostname, //server produção
// 		// server: '192.168.0.254', //server local
// 		port: 3000,
// 		// portBackend: 21001, //produção
// 	    portBackend: 21004, //teste
// 	    // portBackend: 21003, //local
// 		frontend: 'http://localhost.com:3000'
// 	};

export const config = { // --------------- server de produção provisório https
	protocol: window.location.protocol === 'http:' ? 'http' : 'https', 
	server: window.location.hostname, 
	portBackend: window.location.protocol === 'http:' ? 21001 : 21002, 
	port: 3000,
	frontend: 'http://localhost.com:3000'
}

export default config;
