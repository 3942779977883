import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import MultiSelect from "@khanacademy/react-multi-select";
import '../css/Default.css';
import axios from 'axios';
import 'react-table/react-table.css';
// import ReactLoading from 'react-loading';
import { populateForm, asyncForEach, compareDates } from '../Utils';
// import swal from '@sweetalert/with-react';
import swal from '@sweetalert/with-react';

class RelatorioRecebimentoSocios extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			relatorio: '',
			categoriasAssociados: [],
			cidades: [],
			regioes: [],
			microRegioes: [],
			relatorioStats: { message: 'Not started', info: [] }
		};

		this.handleSave = this.handleSave.bind(this);
		this.handleSaveCsv = this.handleSaveCsv.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('relatorio');
		const data = new FormData(form);

		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				let data_inicial = data.get('data_inicial')
				let data_final = data.get('data_final')

				if (compareDates(data_inicial, data_final)) {
					await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=RecebimentoSocios', {
						// cidades: data.get('ano'),
						exportacao: false,
						data_inicial: data.get('data_inicial'),
						data_final: data.get('data_final'),
						cidades: this.state.cidades,
						regioes: this.state.regioes,
						microRegioes: this.state.microRegioes,
						categorias: this.state.categoriasAssociados
					}).then(async (r) => {
						if (r.data.status === 'success') {
							
							this.setState({ relatorio: ''})
							this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/RecebimentoSocios/RecebimentoSocios.pdf'})
						} else this.setState({ relatorio: ''})
						
					})
				} else {
					swal({
						title: 'Informe o período!',
						text: 'Verifique a Data Inicial e Final',
						icon: 'error'
					})
				}
				
			}
			
		});
		
	}

	handleSaveCsv(e) {
		e.preventDefault();
		const form = document.getElementById('relatorio');
		const data = new FormData(form);

		swal({
			title: 'Atenção!',
			text: 'Deseja exportar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				let data_inicial = data.get('data_inicial')
				let data_final = data.get('data_final')

				if (compareDates(data_inicial, data_final)) {
					await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=RecebimentoSocios', {
						// cidades: data.get('ano'),
						exportacao: true,
						data_inicial: data.get('data_inicial'),
						data_final: data.get('data_final'),
						cidades: this.state.cidades,
						regioes: this.state.regioes,
						microRegioes: this.state.microRegioes,
						categorias: this.state.categoriasAssociados
					}).then(async (r) => {
						if (r.data.status === 'success') {
						
							window.open(config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/RecebimentoSocios/RecebimentoSocios.csv', '_none')
						} else this.setState({ relatorio: ''})	
						
					})
				} else {
					swal({
						title: 'Informe o período!',
						text: 'Verifique a Data Inicial e Final',
						icon: 'error'
					})
				}
				
			}
			
		});
		
	}


	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategoriasAssociados = this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoriasAssociados = {
					type: 'categoriasAssociados',
					display: item.descricao,
					label: item.descricao,
					value: item.pk_cat
				};
				await combosCategoriasAssociados.push(categoriasAssociados);
			});
			let categoriasAssociados = {
				type: 'categoriasAssociados',
				display: 'SEM CATEGORIA',
				label: 'SEM CATEGORIA',
				value: 'null'
			};
			await combosCategoriasAssociados.push(categoriasAssociados);
			this.setState({ combos: this.state.combos });

			await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRegioes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosRegioes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let regiao = {
						type: 'regioes',
						display: item.descricao,
						label: item.descricao,
						value: item.pk_reg
					};
					await combosRegioes.push(regiao);
				});
				let regiao = {
					type: 'regioes',
					display: 'SEM REGIÃO',
					label: 'SEM REGIÃO',
					value: 'null'
				};
				await combosRegioes.push(regiao);
				this.setState({ combos: this.state.combos });

				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getMicroRegioes')
				.then((r) => r.json())
				.then(async (r) => {
					let combosMicroRegioes = this.state.combos;
					await asyncForEach(r, async (item) => {
						let microRegiao = {
							type: 'microRegioes',
							display: item.descricao,
							label: item.descricao,
							value: item.pk_mic
						};
						await combosMicroRegioes.push(microRegiao);
					});
					let microRegiao = {
						type: 'microRegioes',
						display: 'SEM MICRO REGIÃO',
						label: 'SEM MICRO REGIÃO',
						value: 'null'
					};
					await combosMicroRegioes.push(microRegiao);
					this.setState({ combos: this.state.combos });

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
					.then((r) => r.json())
					.then(async (r) => {
						let combosCidades = this.state.combos;
						await asyncForEach(r, async (item) => {
							let cidade = {
								type: 'cidades',
								display: item.nome,
								label: item.nome,
								value: item.pk_cid
							};
							await combosCidades.push(cidade);
						});
						let cidade = {
							type: 'cidades',
							display: 'SEM CIDADE',
							label: 'SEM CIDADE',
							value: 'null'
						};
						await combosCidades.push(cidade);
						this.setState({ combos: this.state.combos });
					});
				});
			});
		});

		
	}



	render() {
		let categoriasAssociados = this.state.combos.filter((item) => {
			return item.type === 'categoriasAssociados';
		});
		let regioes = this.state.combos.filter((item) => {
			return item.type === 'regioes';
		});
		let microRegioes = this.state.combos.filter((item) => {
			return item.type === 'microRegioes';
		});
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relatório - Recebimento de Sócios</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="relatorio" name="relatorio" onSubmit={this.submitData}>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Categorias de Associados</label>
									<MultiSelect
										options={categoriasAssociados}
										selected={this.state.categoriasAssociados}
										onSelectedChanged={selected => this.setState({categoriasAssociados: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Regiões</label>
									<MultiSelect
										options={regioes}
										selected={this.state.regioes}
										onSelectedChanged={selected => this.setState({regioes: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Micro Regiões</label>
									<MultiSelect
										options={microRegioes}
										selected={this.state.microRegioes}
										onSelectedChanged={selected => this.setState({microRegioes: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Cidades</label>
									<MultiSelect
										options={cidades}
										selected={this.state.cidades}
										onSelectedChanged={selected => this.setState({cidades: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="col-sm-2" style={{ marginBottom: '15px' }}>
									<label htmlFor="ordenacao">Data Inicial</label>
									<input type="date" name="data_inicial" className="form-control"/>
								</div>
								<div className="col-sm-2" style={{ marginBottom: '15px' }}>
									<label htmlFor="ordenacao">Data Final</label>
									<input type="date" name="data_final" className="form-control"/>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar PDF
							</button>
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '5px' }}
								onClick={this.handleSaveCsv}
							>
								Exportar CSV
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioRecebimentoSocios;
