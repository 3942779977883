import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import Autocomplete from '../components/Autocomplete';
import '../css/Default.css';
// import {LinkContainer}  from 'react-router-bootstrap'
import { Tabs, Tab } from 'react-bootstrap';
import 'react-table/react-table.css';
import { asyncForEach, populateForm } from '../Utils';
import swal from '@sweetalert/with-react';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class Parametros extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			selected: {
				cidade: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.selectCidade = this.selectCidade.bind(this);
	}

	selectCidade(item) {
		this.setState({
			selected: {
				cidade: item
			}
		});
	}

	populaAnos(item, id) {
		return <option value={item - 2000}>{item}</option>;
	}

	populaVencto(item, id) {
		return <option value={item}>{item}</option>;
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Cidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidades',
						display: item.descricao,
						value: item.pk_cid
					};
					await combosCidades.push(cidades);
				});
			});

		this.setState({ combos: this.state.combos });

		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let selectedCidade = this.state.combos.filter((item) => {
						return item.type === 'cidades' && item.value === r[0].fk_cid;
					});
					this.setState({
						selected: {
							cidade: selectedCidade[0]
						}
					});
					let form = document.getElementById('registroParametros');
					// r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					// r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					// console.log(r[0].competencia_mes, r[0].competencia_ano)
					// r[0].valor_anape = r[0].valor_anape.toFixed(2).replace(".", ",")
					populateForm(form, r[0]);
				}
			});

		this.setState({ edit: true });
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
	}

	async modelingData(data) {
		//Trata os campos
	}

	handleSave(e) {
		e.preventDefault();
		this.submitData(e);
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	validateData(data) {
		let valid = { isValid: true };
		//Consistências

		//competencia_mes
		// if (data.get('competencia_mes')) {
		// 	valid = { isValid: true };
		// } else {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo MÊS da COMPETÊNCIA ATUAL.' };
		// }

		//competencia_ano
		// if (data.get('competencia_ano')) {
		// 	valid = { isValid: true };
		// } else {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo ANO da COMPETÊNCIA ATUAL.' };
		// }

		//valor_anape
		// if (Number(data.get('valor_anape')) > 0) {
		// 	valid = { isValid: true };
		// } else {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo VALOR ANAPE.' };
		// }

		//vencto_mensalidade
		// if (data.get('vencto_mensalidade')) {
		// 	valid = { isValid: true };
		// } else {
		// 	return {
		// 		isValid: false,
		// 		title: 'Campo inválido!',
		// 		message: 'Verifique o campo DIA VENCIMENTO MENSALIDADE.'
		// 	};
		// }

		console.log(valid);
		//Retorna resultado
		return valid;
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroParametros');
		const data = new FormData(form);

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Valor para sql
		// let valor_anape = data.get('valor_anape');
		// valor_anape = valor_anape.replace(',', '.');
		// console.log(valor_anape);
		// data.set('valor_anape', valor_anape);

		//Consistências
		let validate = this.validateData(data);

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		// if (Number(data.get('competencia_mes') < 10)) {
		// 	let competencia = '0' + data.get('competencia_mes') + data.get('competencia_ano');
		// 	console.log(competencia);
		// 	data.set('competencia', competencia);
		// 	data.delete('competencia_mes');
		// 	data.delete('competencia_ano');
		// } else {
		// 	let competencia = String(data.get('competencia_mes')) + String(data.get('competencia_ano'));
		// 	data.set('competencia', competencia);
		// 	console.log(competencia);
		// 	data.delete('competencia_mes');
		// 	data.delete('competencia_ano');
		// }

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			object[key] = value;
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/editParametros?pk=999', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							// window.history.back()
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoParametro', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							// window.history.back()
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	listaVencto() {
		let listaVencto = [];
		for (let i = 1; i <= 28; i++) {
			listaVencto.push(i);
		}
		return listaVencto;
	}

	render() {
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		// let anos = this.listaAnos().map(this.populaAnos);
		// let vencto = this.listaVencto().map(this.populaVencto);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Parametros" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Parâmetros</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroParametros" name="registroParametros" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									<Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example">
										<Tab eventKey={1} title="Principal">
											<div style={{ paddingTop: '30px' }}>
												<div className="row">
													<div className="col-sm-6">
														<label htmlFor="nome_fantasia">Nome fantasia:</label>
														<input
															type="text"
															name="nome_fantasia"
															className="form-control"
														/>
													</div>
													<div className="col-sm-6">
														<label htmlFor="razao_social">Razão social:</label>
														<input
															type="text"
															name="razao_social"
															className="form-control"
														/>
													</div>
													<div className="col-sm-3">
														<label htmlFor="cnpj">CNPJ:</label>
														<input
															type="text"
															name="cnpj"
															className="form-control"
															maxLength="14"
														/>
													</div>
													<div className="col-sm-12">
														<label htmlFor="endereco">Endereço:</label>
														<input type="text" name="endereco" className="form-control" />
													</div>
													<div className="col-sm-4">
														<label htmlFor="bairro">Bairro:</label>
														<input type="text" name="bairro" className="form-control" />
													</div>
													<div className="col-sm-4">
														<label htmlFor="cep">CEP:</label>
														<input type="text" name="cep" className="form-control" />
													</div>
													<div className="col-sm-4">
														<div>
															<label htmlFor="cidade">Cidade:</label>
															<Autocomplete
																field="fk_cid"
																items={cidades}
																inModal=""
																selected={this.state.selected.cidade}
																select={this.selectCidade}
															/>
														</div>
													</div>
													{/* <div className="col-sm-4">
														<label htmlFor="valor_anape">Valor ANAPE (R$):</label>
														<input
															type="text"
															id="valor_anape"
															name="valor_anape"
															className="form-control"
															onBlur={onBlurCurrency}
														/> 
													</div>
													<div className="col-sm-4">
														<label htmlFor="vencto_mensalidade">Vencimento:</label>
														<select name="vencto_mensalidade" className="form-control">
															<option value="">Selecione</option>
															{vencto}
														</select>
													</div>
													<br />
													<div className="col-sm-12 bordered">
														<div className="col-sm-12" style={{ marginLeft: '-15px' }}>
															<label>Competência Atual:</label>
														</div>
														<div className="col-sm-4" style={{ marginLeft: '-15px' }}>
															<label htmlFor="competencia_mes">Mês:</label>
															<select name="competencia_mes" className="form-control">
																<option value="">Selecione</option>
																<option value="1">JANEIRO</option>
																<option value="2">FEVEREIRO</option>
																<option value="3">MARÇO</option>
																<option value="4">ABRIL</option>
																<option value="5">MAIO</option>
																<option value="6">JUNHO</option>
																<option value="7">JULHO</option>
																<option value="8">AGOSTO</option>
																<option value="9">SETEMBRO</option>
																<option value="10">OUTUBRO</option>
																<option value="11">NOVEMBRO</option>
																<option value="12">DEZEMBRO</option>
															</select>
														</div>
														<div className="col-sm-4" style={{ marginLeft: '-15px' }}>
															<label htmlFor="competencia_ano">Ano:</label>
															<select name="competencia_ano" className="form-control">
																<option value="">Selecione</option>
																{anos}
															</select>
														</div>
                                                    </div>
                                                    */}
												</div>
											</div>
										</Tab>
									</Tabs>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Parametros;
