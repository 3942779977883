import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import $ from 'jquery';
import '../css/Default.css';
import { Modal, ListGroup, ListGroupItem, Checkbox } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { garanteDate, asyncForEach, populateForm, delay, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import {network} from 'react-icons-kit/ionicons/network'
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { iosFolder } from 'react-icons-kit/ionicons/iosFolder';
import ReactLoading from 'react-loading';
import Autocomplete from '../components/Autocomplete';

class RetornosUnicred extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			file: '',
			filter: [],
			modal: { show: false },
			modalDelete: { show: false },
			edit: false,
			defaultFilePath: '',
			filterChanged: '',
			data: [],
			vincular: { fk_ret: null, associado: null, pk_iru: null, ano: null, fk_ass: null, nosso_numero: null }
		};
		this.handleSelectedFile = this.handleSelectedFile.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeVincular = this.handleChangeVincular.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.closeModalDelete = this.closeModalDelete.bind(this);
		this.modalState = this.modalState.bind(this);
		this.modalDeleteState = this.modalDeleteState.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
		this.vincularAssociado = this.vincularAssociado.bind(this);
		this.showDeleteModal = this.showDeleteModal.bind(this);
		this.handleVincular = this.handleVincular.bind(this);
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	closeModalDelete() {
		this.setState({ modalDelete: { show: false } });
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}

	vincularAssociado(item) {
		let newVincular = this.state.vincular;
		newVincular.fk_ass = item.value;
		newVincular.associado = item;
		this.setState({ vincular: newVincular });
	}


	async showModal(e) {
		e.preventDefault();
		console.log(e);
		let edicao = false;
		let pk = '0';
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('gerarRetornosUnicred');
					form.reset();
					this.setState({ file: [] });
					populateForm(form, r[0]);
				}
			});
		this.setState({ modal: { show: true }, modalDelete: { show: false }, edit: edicao, codigo: pk });
	}

	async showDeleteModal(e, item) {
		e.preventDefault();
		let edicao = false;
		let pk = '0';
		let form = document.getElementById('excluirRetornosUnicred');
		form.reset();
		populateForm(form, {});
		this.setState({ modalDelete: { show: true }, modal: { show: false }, vincular: { pk_iru: item.pk_iru, nosso_numero: item.nosso_numero, fk_ass: null, associado: null, ano: null, fk_ret: item.fk_ret } });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalDeleteState() {
		if (this.state.modalDelete.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	handleSave(e) {
		e.preventDefault();
		if (this.state.file.name) {
			swal({
				title: 'Atenção!',
				text: 'Deseja iniciar a importação?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e);
			});
		} else {
			swal({
				title: 'Erro!',
				text: 'Nenhum arquivo informado!',
				icon: 'error'
			});
		}
	}

	async handleDelete(e) {
		e.preventDefault();
		this.closeModalDelete();
		let form = new FormData(document.getElementById('excluirRetornosUnicred'));
		let orgao = form.get('orgao');
		let competencia =
			Number(form.get('competencia_mes')) < 10
				? '0' + form.get('competencia_mes') + form.get('competencia_ano')
				: form.get('competencia_mes') + form.get('competencia_ano');
		if (orgao && competencia) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getRetornosUnicred?orgao=' +
					orgao +
					'&competencia=' +
					competencia,
				{
					method: 'GET',
					headers: {
						'Content-type': 'application/json'
					}
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.length >= 1) {
						swal({
							title: 'Atenção!',
							text: 'Deseja excluir todos os lançamentos referentes à Competência/Débito ' + (
									Number(form.get('competencia_mes')) < 10 ? ('0' + form.get('competencia_mes') + '/20' + form.get('competencia_ano'))
									: (form.get('competencia_mes') +
										'/20' +
										form.get('competencia_ano'))) +
										" do arquivo '" +
										r[0].arquivo +
										"'?",
							buttons: [ 'Sim', 'Não' ],
							icon: 'warning'
						}).then(async (result) => {
							if (!result) {
								await fetch(
									config.protocol +
										'://' +
										config.server +
										':' +
										config.portBackend +
										'/api/deleteRetornosUnicred?orgao=' +
										orgao +
										'&competencia=' +
										competencia,
									{
										method: 'GET',
										headers: {
											'Content-type': 'application/json'
										}
									}
								)
									.then((r) => r.json())
									.then((r) => {
										console.log(r);
										if (r.message === 'Success!') {
											swal(
												'Exclusão concluída',
												'As informações foram excluídas com sucesso!',
												'success'
											);
										} else {
											swal('Erro!', r.message, 'error');
										}
									});
							}
						});
					} else {
						swal({
							title: 'Erro!',
							text:
								r.length > 0
									? 'Importação selecionada possui valor recebido!'
									: 'Nenhuma importação encontrada com o tipo e Competência/Débito informados!',
							icon: 'error'
						});
					}
				});
		} else {
			swal({
				title: 'Erro!',
				text: 'Informe o tipo!',
				icon: 'error'
			});
		}
	}

	async handleVincular(e) {
		e.preventDefault();
		this.closeModalDelete();
		let vincular = this.state.vincular
		if (vincular.ano && vincular.fk_ass) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/postVincularUnicred',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(vincular)
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (!r.erro) {
						swal('Boleto vinculado', 'Registro vinculado com sucesso!', 'success').then((result) => {
							window.location.reload()
						});
					} else {
						swal({
							title: 'Erro!',
							text: r.erro,
							icon: 'error'
						});
					}
				});
		} else {
			swal({
				title: 'Erro!',
				text: 'Informe o ano e o associado!',
				icon: 'error'
			});
		}
	}

	async submitData(e) {
		// e.preventDefault();
		this.setState({ modal: { show: false } });
		let strAuth = sessionStorage['authsargs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);

		//Bota arquivo no FormData
		const data = new FormData();
		data.append('file', this.state.file, this.state.file.name);
		let ano = String($('#ano').val())

		this.setState({ retornosUnicredStats: { message: 'Started', info: [] } });
		axios
			.post(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/novoRetornoUnicred?fk_usu=' +
					auth.user+
					'&ano='+ano,
				data
			)
			.then(async (response) => {
				this.setState({ retornosUnicredStats: response.data });
				console.log(response.data);
				if (response.data.message === 'Success!') {
					// let count = 0
					while (this.state.retornosUnicredStats.message !== 'Done') {
						await fetch(
							config.protocol + '://' + config.server + ':' + config.portBackend + '/api/checkRetornosUnicred',
							{
								method: 'GET',
								headers: {
									'Content-type': 'application/json'
								}
							}
						)
							.then(async (r) => r.json())
							.then(async (r) => {
								this.setState({ retornosUnicredStats: r });
								console.log(this.state.retornosUnicredStats);
								swal({
									title: 'Importando arquivo RetornosUnicred',
									text: this.state.retornosUnicredStats.message,
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#004136'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});
							});
						if (this.state.retornosUnicredStats.message === 'Error') {
							swal('Importação não concluída', 'Houve um problema na importação.', 'error');
							break;
						}
						if (this.state.retornosUnicredStats.message === 'Cancelado') {
							break;
						}
						await delay(1000);
					}
					if (this.state.retornosUnicredStats.message === 'Error') {
						swal('Geração não concluída', this.state.retornosUnicredStats.info[0], 'error');
					} else if (this.state.retornosUnicredStats.message === 'Cancelado') {
						console.log(this.state.naoEncontrados);
						this.setState({ naoEncontrados: this.state.retornosUnicredStats.info[0].itens });
						let naoEncontrados = this.state.naoEncontrados.map(this.populaNaoEncontrados);
						swal({
							content: (
								<div>
									<p>
										A importação não pode ser concluída. Os registros abaixo não foram encontrados:
									</p>
									<div style={{ overflowY: 'auto', maxHeight: '300px' }}>
										<ListGroup>{naoEncontrados}</ListGroup>
									</div>
								</div>
							),
							icon: 'error'
						});
					} else {
						swal('Geração concluída', 'As informações foram geradas com sucesso!', 'success');
					}
				} else {
					swal('Importação não concluída', response.data.info[0], 'error');
				}
			});
	}

	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros')
			.then((r) => r.json())
			.then(async (r) => {
				this.setState({ defaultFilePath: r[0].pasta_retorno_tesouro });
			});


		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.ano = getParameterByName('ano');
		query.liquidacao = getParameterByName('liquidacao');
		query.vinculados = getParameterByName('vinculados')||'S';
		query.fk_ass = Number(getParameterByName('fk_ass'));
		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		console.log(reg)
		console.log(name)
		console.log(value)
		reg[name] = value;

		this.setState({
			filter: reg
		});
	}

	handleChangeVincular(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.vincular;
		reg[name] = value;
		this.setState({
			vincular: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/sargs/retornos-unicred'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {

			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;
			
			let filtered = await data.filter((item) => {
				// // //Filtro: Nome
				// let tipo = filter.tipo
				// 	? filter.tipo !== 'P'
				// 		? (item.tipo || '').toUpperCase() === (filter.tipo || '')
				// 		: ![ 'M', 'S', 'O' ].includes(item.tipo)
				// 	: true;

				// //Filtro: Competencia - Ano
				let ano = Number(item.ano) === Number(filter.ano) || (filter.ano || '') === '';


				//Filtro: Associado
				let fk_ass = Number(item.fk_ass) === Number(filter.fk_ass) || (filter.fk_ass || '') === '';

				let liquidacao = true;
				if (filter.liquidacao === 'S') liquidacao = Number(item.valor) > 0
				if (filter.liquidacao === 'N') liquidacao = Number(item.valor) === 0

				let vinculados = true
				if (filter.vinculados === 'S') vinculados = item.nomeAss
				if (filter.vinculados === 'N') vinculados = !item.nomeAss


				let dataIni_arquivo = true
				if(filter.dataIni_arquivo) dataIni_arquivo = Date.parse(item.data_arquivo) >= Date.parse(filter.dataIni_arquivo)

				
				let dataFim_arquivo = true
				if(filter.dataFim_arquivo) dataFim_arquivo = Date.parse(item.data_arquivo) <= Date.parse(filter.dataFim_arquivo+'T23:59:59.999Z')

				
				let dataIni_importacao = true
				if(filter.dataIni_importacao) dataIni_importacao = Date.parse(item.data_importacao) >= Date.parse(filter.dataIni_importacao)

				
				let dataFim_importacao = true
				if(filter.dataFim_importacao) dataFim_importacao = Date.parse(item.data_importacao) <= Date.parse(filter.dataFim_importacao+'T23:59:59.999Z')
				console.log(filter.dataFim_importacao)
				let nome_arquivo = true
				if(filter.nome_arquivo) nome_arquivo = item.arquivo.includes(filter.nome_arquivo.toUpperCase())
				// console.log(item.arquivo.includes(filter.nome_arquivo))
				// console.log(Date.parse(item.data_arquivo), )
				//Filtra
				

				return fk_ass && ano && liquidacao && vinculados && dataIni_arquivo && dataFim_arquivo && dataIni_importacao && dataFim_importacao && nome_arquivo;
			});

			let queryString = '?';


			if (filter.ano) {
				if (queryString === '?') {
					queryString = queryString + 'ano=' + filter.ano;
				} else queryString = queryString + '&ano=' + filter.ano;
			}

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}

			if (filter.vinculados) {
				if (queryString === '?') {
					queryString = queryString + 'vinculados=' + filter.vinculados;
				} else queryString = queryString + '&vinculados=' + filter.vinculados;
			}

			if (filter.dataIni_arquivo) {
				if (queryString === '?') {
					queryString = queryString + 'dataIni_arquivo=' + filter.dataIni_arquivo;
				} else queryString = queryString + '&dataIni_arquivo=' + filter.dataIni_arquivo;
			}

			if (filter.dataFim_arquivo) {
				if (queryString === '?') {
					queryString = queryString + 'dataFim_arquivo=' + filter.dataFim_arquivo;
				} else queryString = queryString + '&dataFim_arquivo=' + filter.dataFim_arquivo;
			}

			if (filter.dataIni_importacao) {
				if (queryString === '?') {
					queryString = queryString + 'dataIni_importacao=' + filter.dataIni_importacao;
				} else queryString = queryString + '&dataIni_importacao=' + filter.dataIni_importacao;
			}

			if (filter.dataFim_importacao) {
				if (queryString === '?') {
					queryString = queryString + 'dataFim_importacao=' + filter.dataFim_importacao;
				} else queryString = queryString + '&dataFim_importacao=' + filter.dataFim_importacao;
			}

			if (filter.nome_arquivo) {
				if (queryString === '?') {
					queryString = queryString + 'nome_arquivo=' + filter.nome_arquivo;
				} else queryString = queryString + '&nome_arquivo=' + filter.nome_arquivo;
			}

			console.log(queryString)

			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/sargs/retornos-unicred' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/sargs/retornos-unicred?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRetornosUnicred')
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				console.log(r)
				let items = await this.filterData(r);
				console.log(items)
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	handleSelectedFile(e) {
		$('#nome').val(e.target.files[0].name);
		this.setState({ file: e.target.files[0] });
		console.log(e.target.files[0]);
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2020; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaNaoEncontrados(item, id) {
		return (
			<ListGroupItem id={'list' + id}>
				Matricula:{' '}
				{item.matricula+',  '}
				Contrato:{' '}
				{item.contrato}
				<br />
				{/* <label className="labelSwal">Carteira:</label>
                <input name="codigo" type="text" id={id} className="inputSwal" style={{width: '40%'}} defaultValue='0'></input> */}
			</ListGroupItem>
		);
	}

	populaAnos(item, id) {
		return (
			<option value={item} key={id}>
				{item}
			</option>
		);
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="RetornosUnicred" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Retornos - Unicred</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Ano</label>
									<select
										name="ano"
										id="filtroAno"
										className="selectFiltro"
										value={this.state.filter.ano || ''}
										onChange={this.handleChange}
									>
										<option value="">Ano</option>
										{anos}
									</select>
								</div>
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Associado</label>
									<Autocomplete
										field="filtroFk_ass"
										items={associados}
										inModal=""
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Liquidação</label>
									<select
										name="liquidacao"
										id="filtroLiquidacao"
										className="selectFiltro"
										value={this.state.filter.liquidacao || ''}
										onChange={this.handleChange}
									>
										<option value=""></option>
										<option value="S">Sim</option>
										<option value="N">Não</option>
									</select>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Vinculados</label>
									<select
										name="vinculados"
										id="filtroVinculados"
										className="selectFiltro"
										value={this.state.filter.vinculados || ''}
										onChange={this.handleChange}
									>
										<option value="S">Sim</option>
										<option value="N">Não</option>
									</select>
								</div>
								<div className='itemFiltro'>
									
									<label className="labelFiltro">Data inicial importação</label>
									<input
										type="date"
										value={this.state.filter.dataIni_importacao || ''}
										id="dataIni_importacao"
										name="dataIni_importacao"
										className="inputFiltro"
										onChange={this.handleChange}
									/>
								</div>
								<div className='itemFiltro'>
									
									<label className="labelFiltro">Data final importação</label>
									<input
										type="date"
										value={this.state.filter.dataFim_importacao || ''}
										id="dataFim_importacao"
										name="dataFim_importacao"
										className="inputFiltro"
										onChange={this.handleChange}
									/>
								</div>

								<div className='itemFiltro'>
									
									<label className="labelFiltro">Data inicial arquivo</label>
									<input
										type="date"
										value={this.state.filter.dataIni_arquivo || ''}
										id="dataIni_arquivo"
										name="dataIni_arquivo"
										className="inputFiltro"
										onChange={this.handleChange}
									/>
								</div>
								<div className='itemFiltro'>
									
									<label className="labelFiltro">Data final arquivo</label>
									<input
										type="date"
										value={this.state.filter.dataFim_arquivo || ''}
										id="dataFim_arquivo"
										name="dataFim_arquivo"
										className="inputFiltro"
										onChange={this.handleChange}
									/>
								</div>

								<div className='itemFiltro'>
									
									<label className="labelFiltro">Nome Arquivo</label>
									<input
										value={this.state.filter.nome_arquivo || ''}
										id="nome_arquivo"
										name="nome_arquivo"
										className="inputFiltro"
										onChange={this.handleChange}
									/>
								</div>

								
							</div>
							
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonNovo" onClick={this.showModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Importar
									Arquivo
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Importação de Arquivo Retorno Unicred</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="gerarRetornosUnicred" name="gerarRetornosUnicred" onSubmit={this.submitData}>
												<div>
													<label htmlFor="ano" className="labelModal">
														Ano:
													</label>
													<select
														name="ano"
														id="ano"
														className="form-control"
														style={{ width: '100px' }}
													>
														<option value="">Selecione</option>
														{anos}
													</select>
												</div>
												<div>
													<label className="labelModal">Arquivo</label>
													<input
														type="file"
														name="nome"
														id="html_btn"
														value={this.defaultFilePath}
														onChange={this.handleSelectedFile}
													/>
													<button
														className="ModalButton"
														onClick={this.selectFile}
														style={{
															width: '100px',
															marginLeft: '10px',
															float: 'right',
															marginTop: '2px'
														}}
													>
														<Icon size={20} icon={iosFolder} /> Selecionar...
													</button>
													<input
														type="text"
														id="nome"
														name="nome"
														className="form-control"
														data-parse="uppercase"
														style={{ display: 'inline', width: 'calc(100% - 110px)' }}
														readOnly
													/>

												
												</div>

												
												
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSave}>
												Importar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div className={this.modalDeleteState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Vincular Boleto</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="excluirRetornosUnicred" name="excluirRetornosUnicred">
												<div>
													<label htmlFor="ano" className="labelModal">
														Nosso Numero: {this.state.vincular.nosso_numero}
													</label>
													<div>
														<label htmlFor="ano" className="labelModal">
															Ano:
														</label>
														<select
															name="ano"
															id="ano"
															className="form-control"
															style={{ width: '100px' }}
															value={this.state.vincular.ano || ''}
															onChange={this.handleChangeVincular}
														>
															<option value="">Selecione</option>
															{anos}
														</select>
													</div>
													<div className="itemModal" style={{ width: '300px' }}>
														<label className="labelModal">Associado</label>
														<Autocomplete
															field="fk_ass"
															items={associados}
															inModal=""
															selected={this.state.vincular.associado || { display: '' }}
															select={this.vincularAssociado}
														/>
													</div>
												</div>

												
												
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleVincular}>
												Vincular
											</button>
											<button className="ModalButton" onClick={this.closeModalDelete}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Código',
											accessor: 'pk_iru',
											show: false
										},
										{
											Header: 'Associado',
											accessor: 'nomeAss',
											width: 270,
											Cell: (row) => {
												if (!row.original.nomeAss) {
													return (
														<div>
															<button className="buttonTable" onClick={(e) => this.showDeleteModal(e, row.original)}>
																<Icon size={20} style={{ display: 'inline' }} icon={network} />Vincular Boleto
															</button>
														</div>
													)
												} else return (<div>{row.original.nomeAss}</div>)
											}
										},
										{
											Header: 'Ano',
											accessor: 'ano',
											width: 50
										},
										{
											Header: 'Data Importação',
											accessor: 'data_importacao',
											width: 110,
											Cell: (row) => {
												return (
													<div>
														{garanteDate(row.original.data_importacao)}
													</div>
												)
											}
										},
										{
											Header: 'Data Arquivo',
											accessor: 'data_arquivo',
											width: 100,
											Cell: (row) => {
												return (
													<div>
														{garanteDate(row.original.data_arquivo)}
													</div>
												)
											}
										},
										{
											Header: 'Nosso Número',
											accessor: 'nosso_numero',
											width: 120
										},
										{
											Header: 'Ocorrencia',
											accessor: 'ocorrencia',
											width: 80
										},
										{
											Header: 'Valor Pago',
											accessor: 'valor',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Juros',
											accessor: 'juros',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.juros).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},										
										{
											Header: 'Multa',
											accessor: 'multa',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.multa).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Arquivo',
											accessor: 'arquivo',
										},
										{
											Header: 'Usuário',
											accessor: 'nomeusu',
											width: 120,
											show: false
										},

									]}
									defaultSorted={[
										{
											id: 'pk_bco',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RetornosUnicred;
