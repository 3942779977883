import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import $ from 'jquery';
// import {LinkContainer}  from 'react-router-bootstrap'
// import {Tabs, Tab} from 'react-bootstrap'
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm, dateSql } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroSociedades extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			selected: {
				cidade: [],
				tipo: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.selectCidade = this.selectCidade.bind(this);
		this.selectTipo = this.selectTipo.bind(this);
		this.submitData = this.submitData.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	selectCidade(item) {
		let selected = this.state.selected;
		selected.cidade = item;
		this.setState({ selected: selected });
	}

	selectTipo(item) {
		let selected = this.state.selected;
		selected.tipo = item;
		this.setState({ selected: selected });
	}

	populaCidade(item, id) {
		if (item.type === 'cidade') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaTipo(item, id) {
		if (item.type === 'tipoSociedade') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	async componentDidMount() {
		//Busca valores para combo de filtro

		//Tipos
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposSociedades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosTiposSociedades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tipoSociedade = {
						type: 'tipoSociedade',
						display: item.descricao,
						value: item.pk_tip
					};
					// console.log(cidades)
					await combosTiposSociedades.push(tipoSociedade);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosTiposSociedades });
			});

		//Cidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidade',
						display: item.descricao,
						value: item.pk_cid
					};
					// console.log(cidades)
					await combosCidades.push(cidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosCidades });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			console.log('chegou aqui');
			fetch(
				config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSociedades?pk=' + query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/sociedades';
					} else {
						let form = document.getElementById('registroSociedades');
						let selectedCidade = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid;
						});
						let selectedTipoSociedade = this.state.combos.filter((item) => {
							return item.type === 'tipoSociedade' && item.value === r[0].fk_tip;
						});
						this.setState({
							selected: {
								cidade: selectedCidade[0],
								tipo: selectedTipoSociedade[0]
							}
						});
						r[0].data_fundacao = dateSql(r[0].data_fundacao);
						console.log(r[0]);
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		}
	}

	async submitData(e) {
		e.preventDefault();

		//Pega valores do form
		const form = document.getElementById('registroSociedades');
		const data = new FormData(form);

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			// console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (String(value) === '') {
				object[key] = null;
			} else {
				object[key] = value;
			}
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editSociedades?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoSociedades', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	render() {
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidade';
		});
		let tiposSociedades = this.state.combos.filter((item) => {
			return item.type === 'tipoSociedade';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Sociedades" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Sociedades</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroSociedades" name="registroSociedades" onSubmit={this.submitData}>
								<div className="row">
									<div className="col-sm-4">
										<label>Sociedade:</label>
										<input
											type="text"
											id="nome"
											name="nome"
											data-parse="uppercase"
											className="form-control"
										/>
									</div>
									<div className="col-sm-3">
										<label>Endereço:</label>
										<input
											type="text"
											id="logradouro"
											name="logradouro"
											className="form-control"
											maxLength="11"
											data-parse="uppercase"
										/>
									</div>
									<div className="col-sm-3" style={{ width: '140px' }}>
										<label>CEP:</label>
										<input
											type="text"
											id="cep"
											name="cep"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
										<label>Cidade:</label>
										<Autocomplete
											field="fk_cid"
											items={cidades}
											inModal=""
											selected={this.state.selected.cidade || []}
											select={this.selectCidade}
										/>
									</div>
									<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
										<label>Tipo:</label>
										<Autocomplete
											field="fk_tip"
											items={tiposSociedades}
											inModal=""
											selected={this.state.selected.tipo || []}
											select={this.selectTipo}
										/>
									</div>
									<div className="col-sm-3">
										<label>Email:</label>
										<input
											type="text"
											id="email"
											name="email"
											className="form-control"
											maxLength="11"
											data-parse="uppercase"
										/>
									</div>
									<div className="col-sm-3">
										<label>Site:</label>
										<input
											type="text"
											id="site"
											name="site"
											className="form-control"
											maxLength="11"
											data-parse="uppercase"
										/>
									</div>
									<div className="col-sm-3">
										<label>Contato:</label>
										<input
											type="text"
											id="contato"
											name="contato"
											className="form-control"
											maxLength="11"
											data-parse="uppercase"
										/>
									</div>
									<div className="col-sm-3">
										<label>Telefone 1:</label>
										<input
											type="text"
											id="telefone1"
											name="telefone1"
											className="form-control"
											maxLength="11"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Telefone 2:</label>
										<input
											type="text"
											id="telefone2"
											name="telefone2"
											className="form-control"
											maxLength="11"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Celular:</label>
										<input
											type="text"
											id="celular"
											name="celular"
											className="form-control"
											maxLength="11"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-12">
										<label>Observações:</label>
										<textarea
											type="text"
											maxLength="2000"
											name="observacoes"
											data-parse="uppercase"
											className="form-control"
											style={{ height: '100px' }}
										/>
									</div>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroSociedades;
