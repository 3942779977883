import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import MultiSelect from "@khanacademy/react-multi-select";
import '../css/Default.css';
import axios from 'axios';
import 'react-table/react-table.css';
// import ReactLoading from 'react-loading';
import { populateForm, asyncForEach, compareDates } from '../Utils';
// import swal from '@sweetalert/with-react';
import swal from '@sweetalert/with-react';

class RelatorioListagemSociedades extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			relatorio: '',
			categoriasAssociados: [],
			cidades: [],
			regioes: [],
			microRegioes: [],
			relatorioStats: { message: 'Not started', info: [] }
		};

		this.handleSave = this.handleSave.bind(this);
		this.handleSaveCsv = this.handleSaveCsv.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=ListagemSociedades', {
					exportacao: false,
					cidades: this.state.cidades
				}).then(async (r) => {
					if (r.data.status === 'success') {
						
						this.setState({ relatorio: ''})
						this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/ListagemSociedades/ListagemSociedades.pdf'})
					} else this.setState({ relatorio: ''})
					
				})
			}
			
		});
		
	}

	handleSaveCsv(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja exportar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=ListagemSociedades', {
					exportacao: true,	
					cidades: this.state.cidades
				}).then(async (r) => {
					if (r.data.status === 'success') {
						
						window.open(config.protocol + '://' + config.server + ':' + config.portBackend + '/static/relatorios/ListagemSociedades/ListagemSociedades.csv', '_none')
					} else this.setState({ relatorio: ''})
					
				})
			}
			
		});
		
	}

	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCidades = this.state.combos;
			await asyncForEach(r, async (item) => {
				let cidade = {
					type: 'cidades',
					display: item.nome,
					label: item.nome,
					value: item.pk_cid
				};
				await combosCidades.push(cidade);
			});
			let cidade = {
				type: 'cidades',
				display: 'SEM CIDADE',
				label: 'SEM CIDADE',
				value: 'null'
			};
			await combosCidades.push(cidade);
			this.setState({ combos: this.state.combos });
		});
	}



	render() {
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relatório - Listagem de Sociedades</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="relatorio" name="relatorio" onSubmit={this.submitData}>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label>Cidades</label>
									<MultiSelect
										options={cidades}
										selected={this.state.cidades}
										onSelectedChanged={selected => this.setState({cidades: selected})}
										className="multiSelect relatorio"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
						<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar PDF
							</button>
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '5px' }}
								onClick={this.handleSaveCsv}
							>
								Exportar CSV
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioListagemSociedades;
