import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import { Icon } from 'react-icons-kit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { edit } from 'react-icons-kit/ionicons/edit';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import '../css/Default.css';
import $ from 'jquery';
// import {LinkContainer}  from 'react-router-bootstrap'
// import {Tabs, Tab} from 'react-bootstrap'
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm, dateSql, removeAcento } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroLigas extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			participante: {},
			modalParticipante: { show: false },
			selected: {
				cidade: [],
				universidade: [],
				cidadeParticipante: [],
				universidadeParticipante: [],
				areaAtuacao: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.selectCidade = this.selectCidade.bind(this);
		this.selectUniversidade = this.selectUniversidade.bind(this);
		this.submitData = this.submitData.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleAddParticipante = this.handleAddParticipante.bind(this);
		this.handleEditParticipante = this.handleEditParticipante.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSaveParticipante = this.handleSaveParticipante.bind(this);
		this.showModalParticipante = this.showModalParticipante.bind(this);
		this.selectAreaAtuacao = this.selectAreaAtuacao.bind(this);
		this.selectCidadeParticipante = this.selectCidadeParticipante.bind(this);
		this.selectUniversidadeParticipante = this.selectUniversidadeParticipante.bind(this);
		this.getParticipantes = this.getParticipantes.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	selectCidade(item) {
		let selected = this.state.selected;
		selected.cidade = item;
		this.setState({ selected: selected });
	}

	selectUniversidade(item) {
		let selected = this.state.selected;
		selected.universidades = item;
		this.setState({ selected: selected });
	}

	selectCidadeParticipante(item) {
		let selected = this.state.selected;
		selected.cidadeParticipante = item;
		this.setState({ selected: selected });
	}

	selectUniversidadeParticipante(item) {
		let selected = this.state.selected;
		selected.universidadeParticipante = item;
		this.setState({ selected: selected });
	}

	selectAreaAtuacao(item) {
		let selected = this.state.selected;
		selected.areaAtuacao = item;
		this.setState({ selected: selected });
	}

	populaCidade(item, id) {
		if (item.type === 'cidade') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaUniversidade(item, id) {
		if (item.type === 'universidades') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaUniversidade(item, id) {
		if (item.type === 'area_atuacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	async getParticipantes(pk) {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParticipante?fk_lig='+pk)
		.then((r) => r.json())
		.then(async (r) => {
			this.setState({ dataParticipante: r });
		});
	}

	async componentDidMount() {
		//Busca valores para combo de filtro

		//Universidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getUniversidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosUniversidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let universidades = {
						type: 'universidades',
						display: item.descricao,
						value: item.pk_uni
					};
					// console.log(cidades)
					await combosUniversidades.push(universidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosUniversidades });
			});

		//Cidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidade',
						display: item.descricao,
						value: item.pk_cid
					};
					// console.log(cidades)
					await combosCidades.push(cidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosCidades });
			});

		
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAreaAtuacao')
			.then((r) => r.json())
			.then(async (r) => {
				let combosAreaAtuacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let areaAtuacao = {
						type: 'area_atuacao',
						display: item.descricao,
						value: item.pk_atu
					};
					// console.log(cidades)
					await combosAreaAtuacao.push(areaAtuacao);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosAreaAtuacao });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));



		if (query.pk > 0) {
			console.log('chegou aqui');
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLigas?pk=' + query.pk)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/ligas';
					} else {
						let form = document.getElementById('registroLigas');
						let selectedCidade = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid;
						});
						let selectedUniversidade = this.state.combos.filter((item) => {
							return item.type === 'universidades' && item.value === r[0].fk_uni;
						});
						this.setState({
							selected: {
								cidade: selectedCidade[0],
								universidade: selectedUniversidade[0]
							}
						});
						r[0].data_fundacao = dateSql(r[0].data_fundacao);
						console.log(r[0]);
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });

			if (getParameterByName('pk')) {
				await this.getParticipantes(getParameterByName('pk'))
			}
		}
	}

	async submitData(e) {
		e.preventDefault();

		//Pega valores do form
		const form = document.getElementById('registroLigas');
		const data = new FormData(form);

		let simnao = [ 'atuacao_anestesia', 'atuacao_dor', 'atuacao_med_paliativa' ];

		for (let field of simnao) {
			let value = data.get(field);
			if (value !== 'S') {
				data.set(field, 'N');
			}
		}

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			// console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (String(value) === '') {
				object[key] = null;
			} else {
				object[key] = value;
			}
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editLigas?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoLigas', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	async submitParticipante(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroLigaParticipante');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'));

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		data.append('fk_lig', fk);

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (value !== '') {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		console.log(object)


		var json = JSON.stringify(object);

		//Post no server
		
		if (this.state.editParticipante) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editParticipante?pk='+this.state.participante.pk_lpa,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			).then(r=>{
				swal('Edição realizada', 'Registro editado com sucesso!', 'success').then((result) => {
					this.setState({ modalParticipante: { show: false } });
					this.getParticipantes(getParameterByName('pk'))
				});
			}).catch(error=>{
				swal('Edição não realizada', 'Registro não foi editado. Verifique os campos.', 'error');
			})
		} else {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/novoParticipante',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			).then(r=>{
				swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
					this.setState({ modalParticipante: { show: false } });
					this.getParticipantes(getParameterByName('pk'))
				});
			}).catch(error=>{
				swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
			})
		}
		

	}

	handleCep(e, name){
		e.preventDefault()
		let value = document.getElementsByName(name)[0].value;
        if (value.length >= 8){
            let cep = value
            fetch('https://viacep.com.br/ws/'+cep.replace(/[^\d]/, '')+'/json/').then(r => r.json()).then(r => {
                if (r.erro !== true){
                    console.log(r)
                    let bairro = removeAcento(r.bairro).toUpperCase()
                    let logradouro = removeAcento(r.logradouro).toUpperCase()
                    let localidade = removeAcento(r.localidade).toUpperCase()
					let uf = removeAcento(r.uf).toUpperCase()
					let selected = this.state.selectCidadeParticipante
					let cidades = this.state.combos.filter((item) => {
						return item.type === 'cidade' && removeAcento(item.display).toUpperCase() === localidade+' - '+uf;
					});
					const form = document.getElementById('registroLigaParticipante');
					const data = new FormData(form);
					let object = {};
					data.forEach((value, key) => {object[key] = value});
					// var json = JSON.stringify(object);
					if (cidades.length>0) {
						selected.cidadeResid = cidades[0]
					} else selected.cidadeResid = []
					object.rua = logradouro
					object.bairro = bairro
					populateForm(form, object)
					this.setState({ selected: selected })
                } else {
					alert('CEP inválido!')
					const form = document.getElementById('registroLigaParticipante');
					const data = new FormData(form);
					let object = {};
					data.forEach((value, key) => {object[key] = value});
					let selected = this.state.selectCidadeParticipante
					selected.cidadeResid = []
					object.rua = ''
					object.bairro = ''
					object.fk_cid_resid = null
					populateForm(form, object)
					this.setState({ selected: selected })

                }
            })
        }
    }

	modalStateParticipante() {
		if (this.state.modalParticipante.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	handleSaveParticipante(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o arquivo?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitParticipante(e);
		});
	}

	handleAddParticipante(e) {
		e.preventDefault();
		this.setState({participante: {}});
		document.getElementById('registroLigaParticipante').reset();
		this.showModalParticipante(false, {});
	}

	handleEditParticipante(e) {
		e.preventDefault();
		console.log(this.state.dataParticipante)
		console.log(e.target.name)
		let participante = this.state.dataParticipante.find((item) => Number(item.pk_lpa) === Number(e.target.name))
		console.log(participante)
		participante.data_nascimento = dateSql(participante.data_nascimento||null);
		participante.data_ini_curso = dateSql(participante.data_ini_curso||null);
		participante.data_fim_curso = dateSql(participante.data_fim_curso||null);

		let selected = this.state.selected

		let selectedCidadeParticipante = this.state.combos.filter((item) => {
			return item.type === 'cidade' && item.value === participante.fk_cid;
		});

		let selectedUniversidadeParticipante = this.state.combos.filter((item) => {
			return item.type === 'universidades' && item.value === participante.fk_uni;
		});

		let selectedAreaAtuacao = this.state.combos.filter((item) => {
			return item.type === 'area_atuacao' && item.value === participante.fk_atu;
		});

		selected.cidadeParticipante = selectedCidadeParticipante[0]
		selected.universidadeParticipante = selectedUniversidadeParticipante[0]
		selected.areaAtuacao = selectedAreaAtuacao[0]

		this.setState({ selected })

		populateForm(document.getElementById('registroLigaParticipante'), participante);
		this.showModalParticipante(true, participante);
	}

	async showModalParticipante(edicao, participante) {
		this.setState({ modalParticipante: { show: true }, editParticipante: edicao, participante });
	}

	closeModal() {
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao cancelar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				let selected = this.state.selected
		
				selected.cidadeParticipante = {}
				selected.universidadeParticipante = {}

				this.setState({ modalParticipante: { show: false }, selected });
			}
		});
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteParticipantes?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								// var event = new Event('build');
								this.getParticipantes(getParameterByName('pk'))
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	render() {
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidade';
		});
		let universidades = this.state.combos.filter((item) => {
			return item.type === 'universidades';
		});
		let areas_atuacao = this.state.combos.filter((item) => {
			return item.type === 'area_atuacao';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Ligas" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Liga</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalStateParticipante()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Registro de Participante</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="registroLigaParticipante" name="registroLigaParticipante" onSubmit={this.submitParticipante}>
												<div>
													<label className="labelModal">Nome</label>
													<input
														type="text"
														id="nome"
														name="nome"
														data-parse="uppercase"
														className="form-control"
													/>
												</div>
												<div>
                                                    <label className="labelModal">Data de Nascimento:</label>
                                                    <input type="date" name="data_nascimento" className="form-control" style={{ width: '150px' }}/>
                                                </div>
												<div>
													<label className="labelModal">CPF</label>
													<input
														type="text"
														id="cpf"
														name="cpf"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '150px' }}
													/>
												</div>
												<div>
													<label className="labelModal">RG</label>
													<input
														type="text"
														id="rg"
														name="rg"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '150px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Orgão Expedidor</label>
													<input
														type="text"
														id="orgao_expedidor"
														name="orgao_expedidor"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '100px' }}
													/>
												</div>
												<div>
													<label className="labelModal">CEP</label>
													<input
														type="text"
														id="cep"
														name="cep"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '100px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Rua</label>
													<input
														type="text"
														id="logradouro"
														name="logradouro"
														data-parse="uppercase"
														className="form-control"
													/>
												</div>
												<div>
													<label className="labelModal">Número</label>
													<input
														type="text"
														id="numero"
														name="numero"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '100px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Bairro</label>
													<input
														type="text"
														id="bairro"
														name="bairro"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '200px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Cidade</label>
													<Autocomplete
														field="fk_cid"
														items={cidades}
														inModal="-inModal"
														selected={this.state.selected.cidadeParticipante || []}
														select={this.selectCidadeParticipante}
													/>
												</div>
												<div>
													<label className="labelModal">Email</label>
													<input
														type="text"
														id="email"
														name="email"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '200px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Fone</label>
													<input
														type="text"
														id="fone"
														name="fone"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '150px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Celular</label>
													<input
														type="text"
														id="celular"
														name="celular"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '150px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Facebook</label>
													<input
														type="text"
														id="facebook"
														name="facebook"
														data-parse="uppercase"
														className="form-control"
														style={{ width: '200px' }}
													/>
												</div>
												<div>
													<label className="labelModal">Universidade</label>
													<Autocomplete
														field="fk_uni"
														items={universidades}
														inModal="-inModal"
														selected={this.state.selected.universidadeParticipante || []}
														select={this.selectUniversidadeParticipante}
													/>
												</div>
												<div>
													<label className="labelModal">Período de Curso</label>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<input type="date" name="data_ini_curso" className="form-control" style={{ width: '150px', marginRight: '10px' }}/>
													a
														<input type="date" name="data_fim_curso" className="form-control" style={{ width: '150px', marginLeft: '10px' }}/>
													</div>
												</div>
												<div>
													<label className="labelModal">Área de Atuação</label>
													<Autocomplete
														field="fk_atu"
														items={areas_atuacao}
														inModal="-inModal"
														selected={this.state.selected.areaAtuacao || []}
														select={this.selectAreaAtuacao}
													/>
												</div>
												<div>
													<label className="labelModal">Interesse</label>
													<select
														name="interesse"
														id="interesse"
														className="form-control"
														style={{ width: '300px' }}
													>
														<option value="">Selecione</option>
														<option value="F">Familiar Anestesista</option>
														<option value="D">Desejo pessoal</option>
														<option value="I">Influência Acadêmica</option>
													</select>
												</div>

											
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSaveParticipante}>
												Salvar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroLigas" name="registroLigas" onSubmit={this.submitData}>
								{/* <div style={{ height: 'auto' }}> */}
								<h2>Dados da Liga</h2>
								<div className="row">
									<div className="col-sm-4">
										<label>Nome:</label>
										<input
											type="text"
											id="nome"
											name="nome"
											data-parse="uppercase"
											className="form-control"
										/>
									</div>
									<div className="col-sm-2" style={{ width: '150px' }}>
										<label>Data de Fundação:</label>
										<input
											type="date"
											id="data_fundacao"
											name="data_fundacao"
											className="form-control"
										/>
									</div>
									<div className="col-sm-3">
										<label>Site:</label>
										<input
											type="text"
											id="site"
											name="site"
											className="form-control"
											maxLength="11"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Fanpage:</label>
										<input
											type="text"
											id="fanpage"
											name="fanpage"
											className="form-control"
											maxLength="11"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-5" style={{ marginTop: '20px' }}>
										<label>Área de Atuação:</label>
										<input type="checkbox" name="atuacao_anestesia" value="S" /> Anestesia
										<input type="checkbox" name="atuacao_dor" value="S" /> Dor
										<input type="checkbox" name="atuacao_med_paliativa" value="S" /> Medicina
										Paliativa
									</div>
								</div>
								<legend style={{ marginLeft: '5%', width: '90%', marginTop: '20px' }} />
								<h2>Dados da Instituição de Ensino</h2>
								<div className="row">
									<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
										<label>Formação:</label>
										<Autocomplete
											field="fk_uni"
											items={universidades}
											inModal=""
											selected={this.state.selected.universidade || []}
											select={this.selectUniversidade}
										/>
									</div>
									<div className="col-sm-5">
										<label>Endereço:</label>
										<input
											type="text"
											id="endereco"
											name="endereco"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ width: '120px' }}>
										<label>Número:</label>
										<input
											type="text"
											id="numero"
											name="numero"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Complemento:</label>
										<input
											type="text"
											id="complemento"
											name="complemento"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ width: '180px' }}>
										<label>Bairro:</label>
										<input
											type="text"
											id="bairro"
											name="bairro"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ width: '140px' }}>
										<label>CEP:</label>
										<input
											type="text"
											id="cep"
											name="cep"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
										<label>Cidade:</label>
										<Autocomplete
											field="fk_cid"
											items={cidades}
											inModal=""
											selected={this.state.selected.cidade || []}
											select={this.selectCidade}
										/>
									</div>
									<div className="col-sm-3" style={{ width: '150px' }}>
										<label>Telefone:</label>
										<input
											type="text"
											id="telefone"
											name="telefone"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3" style={{ width: '150px' }}>
										<label>Celular:</label>
										<input
											type="text"
											id="celular"
											name="celular"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Email:</label>
										<input
											type="text"
											id="email"
											name="email"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Site:</label>
										<input
											type="text"
											id="site_ensino"
											name="site_ensino"
											className="form-control"
											// data-parse="number"
										/>
									</div>
									<div className="col-sm-3">
										<label>Fanpage:</label>
										<input
											type="text"
											id="fanpage_ensino"
											name="fanpage_ensino"
											className="form-control"
											// data-parse="number"
										/>
									</div>
								</div>
								<legend style={{ marginLeft: '5%', width: '90%', marginTop: '20px' }} />
								<h2>Participantes</h2>
									{ this.state.edit ? (
									<div>
										<button
											className="buttonVoltar"
											style={{
												marginRight: '10px',
												marginTop: '-40px',
												width: '150px',
												float: 'right'
											}}
											onClick={this.handleAddParticipante}
										>
											<Icon size={20} icon={ic_add_circle} />
											Incluir Participante
										</button>
										<div style={{ paddingBottom: '30px' }}>
											<div style={{ marginTop: '30px' }}>
												<div className="divTabela">
													<ReactTable
														data={this.state.dataParticipante}
														previousText="Anterior"
														nextText="Próximo"
														loadingText="Carregando..."
														pageText="Página"
														ofText="de"
														rowsText="registros"
														noDataText="Nenhum registro encontrado"
														columns={[
															{
																Header: 'Codigo',
																accessor: 'pk_lpa',
																width: 200,
																show: false
															},
															{
																Header: 'Nome',
																accessor: 'nome',
																width: 400
															},
															{
																Header: 'Data Nasc.',
																accessor: 'data_nasc',
																width: 80
															},
															{
																Header: 'CPF',
																accessor: 'cpf',
																width: 130
															},
															{
																Header: 'Email',
																accessor: 'email',
																width: 200
															},
															{
																Header: 'Opções',
																minWidth: 300,
																Cell: (row) => {
																	return (
																		<div className="buttonsDetailColumn">
																			<button
																				className="buttonDetailColumn"
																				onClick={this.handleEditParticipante}
																				name={row.row.pk_lpa}
																				
																			>
																				<Icon size={20} icon={edit} style={{ marginRight: '5px' }}/>
																				Editar
																			</button>
																			<button
																				className="buttonDetailColumn"
																				onClick={(e) => {
																					this.handleDelete(e, row.row.pk_lpa);
																				}}
																			>
																				<Icon size={20} icon={iosTrash} />
																				Excluir
																			</button>
																		</div>
																	);
																}
															}
														]}
														defaultSorted={[
															{
																id: 'nome',
																desc: false
															}
														]}
														defaultPageSize={5}
														className="-striped -highlight"
													/>
												</div>
											</div>
										</div>
									</div>
									) : (
										<div>
											Finalize o cadastro para incluir participantes.
										</div>
									)}
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
								{/* </div> */}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroLigas;
